<template>
    <div>
        <PromptControl @ok="ok"
            @closed="closed"
            v-bind:show="prompt.show"
            v-bind:theme="prompt.theme"
            v-bind:title="prompt.title"
            v-bind:message="prompt.message" />
        <OTPModalControl @matches="matches"
            @cancel="cancel"
            @resend="resend"
            @terminate="terminate"
            @showloader="showloader"
            @hideloader="hideloader"
            v-bind:type="otp.type"
            v-bind:show="otp.show"
            v-bind:otp="otp.otp"
            v-bind:pin="otp.pin"
            v-bind:name="otp.name"
            v-bind:mobile="otp.mobile"
            v-bind:email="otp.email"
            keeploader />
        <MessageBoxControl @closed="closeMessageBox"
            v-bind:show="msg.show"
            v-bind:title="msg.title"
            v-bind:message="msg.message" />
        <ContentWrapperControl class="mt-4">
            <div class="bg-white p-4 rounded shadow mb-4">
                <div class="font-bold text-lg pb-2">Welcome Back!</div>
                <div class="pb-6 leading-normal text-sm text-grey-darkest">You will be redirected to your account once your account has been activated.</div>
                <div class="mb-4">
                    <TextControl @typing="typing"
                        v-bind:value="value"
                        v-bind:placeholder="lang('account.no')"
                        :maxlength="6"
                        type="text"
                        name="account_no"
                        uppercase
                        required />
                </div>
                <div class="w-full flex">
                    <div class="flex-1 mr-2">
                        <ButtonControl v-on:click="send_otp" v-bind:disabled="disabled">Continue</ButtonControl>
                    </div>
                    <div class="flex-1">
                        <ButtonControl v-on:click="logout" theme="error">
                            {{ lang('log.out') }}
                        </ButtonControl>
                    </div>
                </div>
            </div>
        </ContentWrapperControl>
    </div>
</template>

<script>
import ButtonControl from './../../components/controls/ButtonControl.vue';
import ContentWrapperControl from './../../components/controls/ContentWrapperControl.vue';
import MessageBoxControl from './../../components/controls/MessageBoxControl.vue';
import OTP from './../../services/OTP.js';
import OTPModalControl from './../../components/controls/OTPModalControl.vue';
import PromptControl from './../../components/controls/PromptControl.vue';
import TextControl from './../../components/controls/TextControl.vue';
import User from './../../services/User.js';
import mixin from './../../mixin.js';

export default {
    mixins: [
        mixin,
    ],
    components: {
        ButtonControl,
        ContentWrapperControl,
        MessageBoxControl,
        OTPModalControl,
        PromptControl,
        TextControl,
    },
    data() {
        return {
            value: '',
            disabled: true,
            prompt: {
                show: false,
                theme: 'success',
                title: '',
                message: '',
            },
            otp: {
                show: false,
                type: 4,
                otp: '',
                pin: '',
                mobile: '',
                email: '',
                name: '',
            },
            msg: {
                show: false,
                title: '',
                message: '',
            }
        }
    },
    mounted() {
        this.$emit('checkauth');
        this.$emit('nofooter', true);
        this.$emit('shownavbar', {
            title: 'Activate Account',
            show: true,
            back: false,
        });

        if(!this.$store.state.deactivated) {
            this.$router.push('/dashboard');
        }

        this.otp.mobile = this.$store.state.mobile;
        this.otp.email = this.$store.state.email;
        this.otp.name = this.$store.state.first_name;
    },
    methods: {
        typing(obj) {
            this.value = obj.value;    
            this.disabled = !(this.value.length == 6) || !(this.value.toUpperCase() == this.$store.state.account_no);
        },
        closed() {
            this.prompt.show = false;
        },
        send_otp() {
            let that = this;

            that.$emit('showloader');
            OTP.request(this.otp.type, this.otp.name, this.otp.email, this.otp.mobile, this.otp.pin).then(function(response) {
                that.otp.otp = response.data.data.otp;
                that.otp.pin = response.data.data.pin;
                that.$emit('hideloader');
                that.otp.show = true;
            })
            .catch(function(error) {
                let status = error.response.status,
                    message = error.response.data.message;

                if(status != 403) {
                    that.msg.title = 'Error';
                    that.msg.message = message;
                    that.msg.show = true;
                    that.$emit('hideloader');
                }
                else {
                    that.$emit('terminate', status);
                }
            });
        },
        logout() {
            this.prompt.title = this.lang('log.out');
            this.prompt.message = this.lang('are.you.sure.you.want.to.leave');
            this.prompt.show = true;
        },
        ok() {
            let that = this,
                state = this.$store.state;

            that.$emit('showloader');
            User.logout(state.uuid).then(function() {
                that.$cookies.remove('hash');
                that.resetUserData();
                that.$emit('logout');
                that.$emit('hideloader');
                that.$router.push('/');
            })
            .catch(function() {
                that.$emit('terminate', 403);
            });
        },
        hideloader() {
            this.$emit('hideloader');
        },
        showloader() {
            this.$emit('showloader');
        },
        matches() {
            let that = this;

            that.$emit('showloader');
            User.activate(this.$store.state.uuid).then(function() {
                that.$store.state.deactivated = false;
                that.$router.push('/dashboard');
                that.$emit('hideloader');
            })
            .catch(function(error) {
                let status = error.response.status,
                    message = error.response.data.message;

                if(status != 403) {
                    that.msg.title = 'Error';
                    that.msg.message = message;
                    that.msg.show = true;
                    that.$emit('hideloader');
                }
                else {
                    that.$emit('terminate', status);
                }
            });
        },
        cancel() {
            this.otp.show = false;
        },
        resend(otp) {
            this.otp.otp = otp;
        },
        terminate(status) {
            this.$emit('terminate', status);
        },
        closeMessageBox() {
            this.msg.show = false;
        },
    }
}
</script>