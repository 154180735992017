<template>
    <div>
        <ModalControl @closed="closeVotes" 
            v-bind:show="votes" 
            title="My Current Votes"
            dismiss
            nopadd>
            <div class="p-2 bg-grey-light">
                <VoteCardControl v-if="of.length != 0 || id != 'bod'"
                    v-bind:update="update"
                    id="bod"
                    title="Board of Directors"
                    type="Officer"
                    color="bg-theme-bod"
                    noedit />

                <VoteCardControl v-if="ep.length != 0 || id != 'bod'"
                    v-bind:update="update"
                    id="bod"
                    title="Board of Directors"
                    type="Enlisted Personnel"
                    color="bg-theme-bod"
                    noedit />

                <VoteCardControl v-if="ci.length != 0 || id != 'bod'"
                    v-bind:update="update"
                    id="bod"
                    title="Board of Directors"
                    type="Civilian"
                    color="bg-theme-bod"
                    noedit />
                
                <VoteCardControl v-if="$store.state.selected.elecom_of.length != 0"
                    v-bind:update="update"
                    id="elecom_of"
                    title="Election Committee"
                    type="Officer"
                    color="bg-theme-elecom"
                    noedit />

                <VoteCardControl v-if="$store.state.selected.elecom_ep.length != 0"
                    v-bind:update="update"
                    id="elecom_ep"
                    title="Election Committee"
                    type="Enlisted Personnel"
                    color="bg-theme-elecom"
                    noedit />

                <VoteCardControl v-if="$store.state.selected.elecom_ci.length != 0"
                    v-bind:update="update"
                    id="elecom_ci"
                    title="Election Committee"
                    type="Civilian"
                    color="bg-theme-elecom"
                    noedit />

                <VoteCardControl v-if="$store.state.selected.audit.length != 0"
                    v-bind:update="update"
                    id="audit"
                    title="Audit & Supervisory Committee"
                    type="No Category"
                    color="bg-theme-adcom"
                    noedit />
            </div>
        </ModalControl>
        <MessageBoxControl @closed="closed" 
            v-bind:show="display" 
            v-bind:message="message" 
            v-bind:theme="theme"
            title="Oops" />
        <MessageBoxControl @closed="closeNotice"
            v-bind:show="modal.show"
            v-bind:message="modal.message"
            title="Notice"
            theme="success" />
        <PromptControl @ok="ok"
            @closed="closeClear"
            v-bind:title="title"
            v-bind:message="prompt_msg"
            v-bind:show="prompt_show" />
    <div class="w-full h-screen flex flex-col">
        <div v-bind:class="[ color ]">
            <div class="flex pt-3 px-3">
                <div>
                    <div class="h-menu w-menu">
                        <img v-show="backable" v-on:click="back" src="@/assets/icons/papsi_back.svg" />
                    </div>
                </div>
                <div class="flex-grow text-center pt-6 pb-3">
                    <div v-text="title" class="text-xl text-white font-bold uppercase pt-6"></div>
                </div>
                <div>
                    <div class="h-menu w-menu rounded-full overflow-hidden papsi-bg-trans">
                        <div class="p-1">
                            <img v-on:click="openVotes" src="@/assets/icons/papsi_list.svg" />
                        </div>
                    </div>
                </div>
            </div>
            <div class="max-w-limit w-full mx-auto">
                <div class="px-4">
                    <div class="bg-white py-2 w-full rounded-t"></div>
                </div>
            </div>
        </div>
        <div class="max-w-limit w-full mx-auto">
            <div class="px-4">
                <div v-show="id != 'bod'" class="bg-white pb-2 w-full rounded-b border-b">
                    <div v-text="subtitle" class="px-2 text-sm text-center text-grey-darkest uppercase leading-loose"></div>
                </div>
                <div v-show="id == 'bod'" class="bg-white pb-2 px-4 w-full rounded-b border-b flex uppercase shadow-sm">
                    <div class="flex-1 border-r border-grey">
                        <div v-text="of.length" v-bind:class="{ 'text-green' : of.length == bod_officer }" class="text-center text-3xl text-red font-bold pb-2"></div>
                        <div class="text-center border-t border-grey text-xs pt-2 font-bold">Officer</div>
                    </div>
                    <div class="flex-1 border-r border-grey">
                        <div v-text="ep.length" v-bind:class="{ 'text-green' : ep.length == bod_ep }" class="text-center text-3xl text-red font-bold pb-2"></div>
                        <div class="text-center border-t border-grey text-xs pt-2 font-bold">Enlisted<br />Personnel</div>
                    </div>
                    <div class="flex-1">
                        <div v-text="ci.length" v-bind:class="{ 'text-green' : ci.length == bod_civilian }" class="text-center text-3xl text-red font-bold pb-2"></div>
                        <div class="text-center border-t border-grey text-xs pt-2 font-bold">Civilian</div>
                    </div>
                </div>
            </div>
        </div>
        <div class="flex-grow w-full overflow-auto">
            <ContentWrapper>
                <div v-if="loaded" class="flex pt-4 w-full">
                    <ul class="list-none m-0 p-0 block w-full">
                        <VoteItemControl v-for="(item, index) in data" 
                            @toggled="toggled"
                            @overselect="overselect"
                            @overselectedBOD="overselectedBOD"
                            @selected="selectedItem"
                            @unselected="unselectedItem"
                            v-bind:id="item.key"
                            v-bind:group="category"
                            v-bind:index="index"
                            v-bind:name="item.name"
                            v-bind:type="item.type"
                            v-bind:category="item.type_desc"
                            v-bind:selected="chosen.indexOf(item.key) != -1"
                            v-bind:count="chosen.length"
                            v-bind:max="max"
                            v-bind:key="item.key"
                            v-bind:freeze="freeze || !isTypeAvailable(item.key)"
                            v-bind:available="isTypeAvailable(item.key)"
                            v-bind:color="color" />
                    </ul>
                </div>
            </ContentWrapper>
            <div ref="footerspacer"></div>
        </div>
    </div>
    <div ref="footerbar" class="bg-white p-2 shadow flex w-full fixed z-footer papsi-bottom-footer-z">
        <div class="papsi-navbar-w">
            <ButtonControl v-on:click="clear" v-bind:disabled="!clearable" theme="error">Clear</ButtonControl>
        </div>
        <div class="flex-grow text-center text-md papsi-navbar-h-lh">
            <span v-text="chosen.length"></span> of <span v-text="max"></span>
        </div>
        <div class="papsi-navbar-w">
            <ButtonControl v-on:click="next" v-bind:disabled="disabled">{{ btn_label }}</ButtonControl>
        </div>
    </div>
    </div>
</template>

<script>
import ButtonControl from './../controls/ButtonControl.vue';
import ContentWrapper from './../controls/ContentWrapperControl.vue';
import Election from './../../services/Election.js';
import MessageBoxControl from './../../components/controls/MessageBoxControl.vue';
import ModalControl from './../../components/controls/ModalControl.vue';
import PromptControl from './../../components/controls/PromptControl.vue';
import VoteCardControl from '../../components/controls/VoteCardControl.vue';
import VoteItemControl from './../controls/VoteItemControl.vue';

export default {
    name: 'v-vote',
    components: {
        ButtonControl,
        ContentWrapper,
        MessageBoxControl,
        ModalControl,
        PromptControl,
        VoteCardControl,
        VoteItemControl,
    },
    props: {
        category: {
            type: String,
            default: 'bod',
        },
        id: {
            type: String,
            default: 'bod',
        },
        type: {
            type: String,
            default: null,
        },
        backable: {
            type: Boolean,
            default: false,
        },
        max: {
            type: Number,
            default: 1,
        },
        title: {
            type: String,
            default: null,
        },
        href: {
            type: String,
        },
        subtitle: {
            type: String,
            default: null,
        },
        color: {
            type: String,
            default: 'bg-theme-bod',
        },
        notice: {
            type: String,
        },
    },
    data() {
        return {
            data: [],
            loaded: false,
            disabled: true,
            clearable: false,
            display: false,
            message: '',
            theme: 'error',
            chosen: [],
            prompt_title: '',
            prompt_show: false,
            prompt_msg: '',
            votes: false,
            update: false,
            freeze: false,
            show_message: false,
            bod_officer: 3,
            bod_ep: 3,
            bod_civilian: 2,
            of: [],
            ep: [],
            ci: [],
            btn_label: 'Next',
            modal: {
                show: false,
                message: '',
            },
        }
    },
    mounted() {
        let that = this,
            candidates = this.$store.state.candidates,
            voted = this.$store.state.selected[this.id],
            data = candidates[this.id];

        if(voted.length != 0) {
            voted.forEach(function(item) {
                that.chosen.push(item);
            });
        }

        if(this.$store.state.election.registered) {
            if(data.length == 0) {
                this.$emit('preload');
                this.load();
            }
            else
            {
                this.loaded = true;
                this.$emit('loaded');
                data.forEach(function(item) {
                    that.data.push(item);
                });
            }

            if(this.id == 'bod')
            {
                let selected = this.$store.state.selected.bod;

                that.of.splice(0, that.of.length);
                that.ep.splice(0, that.ep.length);
                that.ci.splice(0, that.ci.length);

                selected.forEach(function(item) {
                    let detail = that.getItemByKey(item);

                    if(detail.type == 1)
                    {
                        that.of.push(detail);
                    }
                    else if(detail.type == 2)
                    {
                        that.ep.push(detail);
                    }
                    else if(detail.type == 3)
                    {
                        that.ci.push(detail);
                    }
                });
            }

            this.clearable = this.chosen.length > 0;
            this.disabled = this.chosen.length != this.max;
            this.selected = this.chosen.length;
            this.freeze = this.chosen.length == this.max;
            this.modal.message = this.notice;
            this.modal.show = this.chosen.length == 0;
        }

        if(this.$store.state.election.done)
        {
            this.btn_label = 'Back';
        }
        else {
            this.btn_label = 'Next';
        }

        this.setSpacer();
    },
    methods: {
        setSpacer() {
            this.$refs.footerspacer.style.height = (parseInt(this.$refs.footerbar.offsetHeight) + 20) + "px";
        },
        openVotes() {
            this.votes = true;
        },
        closeNotice() {
            this.modal.show = false;
        },
        closeVotes() {
            this.votes = false;
        },
        back() {
            if(this.id == 'bod')
            {
                this.theme = 'error';
                this.message = 'Please finish voting first before leaving.';
                this.display = true;
            }
            else {
                if(this.chosen.length == this.max) {
                    this.$router.back();
                }
                else {
                    this.theme = 'error';
                    this.message = 'You cannot go back until you have selected the required number of votes for this category.';
                    this.display = true;
                }
            }
        },
        overselectedBOD(type) {
            this.theme = 'error';

            if(type == 1) {
                this.message = 'You have reached the maximum number of officer for Board of Directors.';
            }
            else if(type == 2) {
                this.message = 'You have reached the maximum number of enlisted personnel for Board of Directors.';
            }
            else if(type == 3) {
                this.message = 'You have reached the maximum number of civilian for Board of Directors.';
            }

            this.display = true;
        },
        load() {
            let that = this;

            Election.candidates(this.category, this.type).then(function(response) {
                let data = response.data.data;

                data.forEach(function(item) {
                    that.data.push(item);
                });

                that.loaded = true;
                that.$emit('loaded');
                that.$store.state.candidates[that.id] = data;
            }).catch(function(error) {
                let status = error.response.status,
                    message = error.response.data.message;

                if(status != 403) {
                    that.theme = 'error';
                    that.message = message;
                    that.display = true;
                }
                else {
                    that.$emit('terminate', status);
                }
            });
        },
        closed() {
            this.display = false;
        },
        getItemByKey(key) {
            let data = this.$store.state.candidates.bod,
                detail = null;

            data.forEach(function(item) {
                if(key == item.key) {
                    detail = item;
                }
            });

            return detail;
        },
        isTypeAvailable(key) {
            let available = true;

            if(this.id == 'bod')
            {
                let detail = this.getItemByKey(key);
                
                if(detail.type == 1)
                {
                    available = (this.bod_officer - this.of.length) > 0;
                }
                else if(detail.type == 2)
                {
                    available = (this.bod_ep - this.ep.length) > 0;
                }
                else if(detail.type == 3)
                {
                    available = (this.bod_civilian - this.ci.length) > 0;
                }
            }

            return available;
        },
        selectedItem(key) {
            if(this.id == 'bod')
            {
                let item = this.getItemByKey(key);

                if(item.type == 1)
                {
                    this.of.push(item);
                }
                else if(item.type == 2)
                {
                    this.ep.push(item);
                }
                else if(item.type == 3)
                {
                    this.ci.push(item);
                }
            }
        },
        unselectedItem(key) {
            if(this.id == 'bod')
            {
                let index = -1,
                    detail = this.getItemByKey(key);
            
                if(detail.type == 1) {
                    this.of.forEach(function(item, n) {
                        if(item.key == key) {
                            index = n;
                        }
                    });

                    if(index !== -1)
                    {
                        this.of.splice(index, 1);
                    }
                }
                else if(detail.type == 2) {
                    this.ep.forEach(function(item, n) {
                        if(item.key == key) {
                            index = n;
                        }
                    });

                    if(index !== -1)
                    {
                        this.ep.splice(index, 1);
                    }
                }
                else if(detail.type == 3) {
                    this.ci.forEach(function(item, n) {
                        if(item.key == key) {
                            index = n;
                        }
                    });

                    if(index !== -1)
                    {
                        this.ci.splice(index, 1);
                    }
                }
            }
        },
        toggled(key) {
            if(this.chosen.indexOf(key) == -1) {
                this.chosen.push(key);
            }
            else {
                let index = this.chosen.indexOf(key);
                this.chosen.splice(index, 1);
            }

            this.clearable = this.chosen.length > 0;
            this.disabled = this.chosen.length != this.max;
            this.selected = this.chosen.length;
            this.update = !this.update;
            this.freeze = this.chosen.length == this.max;

            this.updateStore();
            this.$emit('toggled', key);
        },
        updateStore() {
            let state = this.$store.state.selected[this.id];

            state.splice(0, state.length);

            this.chosen.forEach(function(item) {
                state.push(item);
            });
        },
        overselect() {
            this.theme = 'error';
            this.message = 'You have reached the maximum number of candidates to vote for this category.';
            this.display = true;
        },
        clear() {
            this.prompt_title = 'Clear';
            this.prompt_msg = 'Are you sure you want to clear all selected candidates.';
            this.prompt_show = true;
        },
        closeClear() {
            this.prompt_show = false;
        },
        ok() {
            this.chosen.splice(0, this.chosen.length);

            if(this.id == 'bod')
            {
                this.of.splice(0, this.of.length);
                this.ep.splice(0, this.ep.length);
                this.ci.splice(0, this.ci.length);
            }

            this.clearable = this.chosen.length > 0;
            this.disabled = this.chosen.length != this.max;
            this.selected = this.chosen.length;
            this.freeze = this.chosen.length == this.max;
            this.updateStore();
        },
        next() {
            if(this.$store.state.election.done) {
                this.$router.push('/election/vote/summary');
            }
            else {
                if(this.chosen.length == this.max) {
                    this.$router.push(this.href);
                }
            }
        },
    }
}
</script>

<style scoped>
.papsi-navbar-h-lh {
    line-height: 40px;
}
.papsi-navbar-w {
    width: 100px;
}
.papsi-bg-trans {
    background-color: rgba(255, 255, 255, 0.30);
}
.papsi-bottom-footer-z {
    bottom: 0px;
    left: 0px;
}
.papsi-category-h {
    height: 42px;
    line-height: 16px;
}
</style>