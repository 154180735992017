<template>
    <div class="bg-white p-4 mb-3 rounded shadow border-b border-grey">
        <div class="pb-2 flex">
            <div v-text="title" class="flex-grow text-md text-grey-darkest papsi-info-group-lh"></div>
            <div class="px-1 h-icon">
                <img v-bind:src="require('@/assets/icons/papsi_more.svg')" />
            </div>
        </div>
        <div class="border-t">
            <slot></slot>
        </div>
    </div>
</template>

<script>
export default {
    name: 'v-info-group',
    props: {
        title: {
            type: String,
            required: true,
        }
    }
}
</script>

<style scoped>
.papsi-info-group-lh {
    line-height: 24px;
}
</style>