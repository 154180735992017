<template>
    <div>
        <OTPModalControl @cancel="cancel"
            @resend="resend"
            @matches="matches"
            @showloader="showLoader"
            @hideloader="hideLoader"
            v-bind:show="otp_show"
            v-bind:otp="otp"
            v-bind:pin="pin"
            v-bind:email="email"
            v-bind:mobile="mobile"
            v-bind:name="name"
            v-bind:type="otp_type"
            :delay="0" />
        <MessageBoxControl @closed="closed" 
            v-bind:show="display" 
            v-bind:message="message" 
            v-bind:theme="theme"
            title="Oops" />
        <div v-show="show_message" class="p-3 fixed w-full" style="bottom: 60px">
            <div class="rounded text-white text-xs leading-loose papsi-bg-coffee-trans">
                <div class="p-3">
                    Scroll down at the bottom of the page to proceed in voting.
                </div>
            </div>
        </div>
        <ContentWrapper>
            <div class="bg-white shadow rounded p-4 mt-4">
                <div class="text-sm mb-4 p-2 leading-normal">
                  <div class="pb-4 font-bold text-center text-lg">REVISED PAFCPIC ELECTION CODE 2023</div>
                  <div class="pb-2 font-bold mt-4">ARTICLE 1. – TITLE</div>
                  <div class="pb-2 mb-2">
                      This code shall be known as the “Election Code of the Philippine Army
                      Finance Center Producers Integrated Cooperative (PAFCPIC).”
                  </div>
                  <div class="py-2 font-bold">ARTICLE 2. – DEFINITION OF TERMS.</div>
                  <div class="pb-2 mb-2">
                      Code – shall mean the Election Code of the Philippine Army Finance
                      Center Producers Integrated Cooperative.
                  </div>
                  <div class="py-2 mb-2">
                      <b>Member Entitled to Vote (MEV)</b> – is a member who satisfies the following
                      criteria/conditions:
                  </div>
                  <div class="py-2">
                      <b>a.</b> Must be a regular member of the cooperative for at least one (1) year
                      and has contributed share capital equivalent to twelve monthly
                      contribution one (1) year as of the end of the Fiscal Year prior to the
                      scheduled General Assembly meeting called for the purpose.
                  </div>
                  <div class="py-2">
                      <b>b.</b> Has not been delinquent in the payment of any amortization of his/her
                      loan with the cooperativeimmediately preceding the election.
                  </div>
                  <div class="py-2">
                      <b>c.</b> Participates and attends in Cooperative’s parliamentary affairs,
                      (Regular or Representative. A member who absented himself for two (2)
                      Immediately pastgeneral assembly meetingsbut who attends the current one
                      shall be restored to his voting rights in the next General Assembly
                      Meeting.
                  </div>
                  <div class="py-2">
                      <b>e.</b> Has attended and completed the prescribed Pre-Membership Cooperative
                      Seminar.
                  </div>
                  <div class="py-2">
                      <b>f.</b> Obeys, the rules and regulations provided by R.A. 9520 and other
                      related legislative enactments, rules and regulations issued by the
                      Cooperative Development Authority (CDA), PAFCPIC By-Laws, decisions of
                      the General Assembly and the Board of Directors and policiespromulgated
                      by the CDA and byPAFCPIC.
                  </div>
                  <div class="py-2 mb-2">
                      <b>g.</b> Promoted the aims and purpose of the cooperative;the success of its
                      business; the welfare of its members; and the cooperative movement as a
                      whole.
                  </div>
                  <div class="py-2 mb-2">
                      <b>(3) Quorum</b> – Twenty-Five percent (25%) of the members entitled to vote
                      who participated in the three (3) previous General Assembly, whichever
                      is higher.
                  </div>
                  <div class="py-2">
                      <b>(4) General Assembly (GA)</b> – composed of members entitled to vote duly
                      assembled, which has the power:
                  </div>
                  <div class="py-2">
                      <b>a.</b> To elect directors, officers and committee members and to remove them
                      for cause;
                  </div>
                  <div class="py-2">
                      <b>b.</b> To hear and pass upon the reports of the Board of Directors, Officers
                      and Committees;
                  </div>
                  <div class="py-2">
                      <b>c.</b> To exercise final authority on all matters vitally affecting the
                      Cooperative, subject to legal restrictions;
                  </div>
                  <div class="py-2">
                      <b>d.</b> To act on the amendments in the Article of Cooperation and/or
                      By-laws;
                  </div>
                  <div class="py-2">
                      <b>e.</b> To approve developmental plans of the Cooperative; and,
                  </div>
                  <div class="py-2 mb-2">
                      <b>f.</b> To exercise all the powers expressly provided by law and the By-laws
                      or reasonably implied therefrom.
                  </div>
                  <div class="py-2 mb-2">
                      <b>(5) Board of Directors (BOD)</b> – Duly elected official by the General
                      Assembly who shall direct and supervise the business, manage the
                      property of the Cooperative and may by resolution, exercise all such
                      powers of the Cooperative that are not reserved for the GA under the
                      existing laws.
                  </div>
                  <div class="py-2 mb-2">
                      <b>(6) Poll Clerk</b> – a member-cooperator designated by the Election
                      Committee (ELECOM) to assist in receiving and processing of
                      representative forms, conduct of registration and canvassing of votes,
                      and other election duties.
                  </div>
                  <div class="py-2 mb-2">
                      <b>(7) Protest</b> – a sworn complaint filed by a candidate at any stage of the
                      election period.
                  </div>
                  <div class="py-2 mb-2">
                      <b>(8) Representative Vote</b> – a vote delegated by a member to another member
                      of the Cooperative for the purpose of election and other forms of action
                      requiring the assent and/or dissent of the member.
                  </div>
                  <div class="py-2 mb-2">
                      <b>(9) Representative Vote Holder</b> – a member with a delegated vote from
                      another member of the Cooperative for the purpose of election.
                  </div>
                  <div class="py-2 mb-2">
                      <b>(10) Representative Form</b> – an official form in which a regular member
                      nominates and appoints his/her representative to vote on behalf of
                      his/her during election.
                  </div>
                  <div class="py-2 mb-2">
                      <b>(11) Candidate</b> – a member declared to be in good standing and is deemed
                      qualified by the ELECOM to run in any elective position of the
                      Cooperative.
                  </div>
                  <div class="py-2 mb-2">
                      <b>(12) Walk-in Voter</b> – a member who appears during the Election Day who
                      constitutes a single vote.
                  </div>
                  <div class="py-2 mb-2">
                      <b>(13) Validation Clerk</b> – a member cooperator appointed by the ELECOM to
                      perform validation duties for a certain period based on the
                      representative forms submitted.
                  </div>
                  <div class="py-2 mb-2">
                      <b>(14) Polling Place</b> - the designated place where the election will be
                      held.
                  </div>
                  <div class="py-2 mb-2">
                      <b>(15) Election Committee (ELECOM)</b> – Promulgates rules and regulations in
                      the conduct of election, implement election rules and guidelines duly
                      approved by the GA: recommend necessary amendments to the election rules
                      and guidelines, in consultation with the Board of Directors for the GA’s
                      approval, pass upon the qualifications of the candidates, supervise the
                      conduct, manner and procedure of election and other election related
                      activities and on the changes thereto, canvass and certify in writing
                      the results of the election, proclaim the winning candidates and hear
                      and decide election protests.
                  </div>
                  <div class="py-2 mb-2">
                      <b>(16) Mobile App</b> - a downloadable online application designed to be used
                      by members to cast their votes during elections with the use of gadgets
                      such as smartphones, tablets, and desktop computers.
                  </div>
                  <div class="py-2 mb-2">
                      <b>(17) PAFCPIC Web Page</b> - is the official web page of PAFCPIC where all
                      announcements and information about PAFCPIC are published.
                  </div>
                  <div class="py-2 mb-4">
                      <b>(18) PSO On-Site Voting</b> - a manner of the election in which members can
                      cast their votes by way of visiting their designated PSOs and utilizing
                      the designated voting station installed thereat. ELECOM Representatives
                      or deputized Poll Clerks will oversee the conduct of voting at the PSOs.
                  </div>
                  <div class="py-2 font-bold">ARTICLE 3. – APPLICABILITY</div>
                  <div class="py-2 mb-2">
                      This Code shall govern the procedures in the conduct of election of the
                      BOD and other elective positions of the Cooperative.
                  </div>
                  <div class="py-2 font-bold">
                      ARTICLE 4. – RIGHT AND OBLIGATION TO REGISTER AND VOTE
                  </div>
                  <div class="py-2 mb-2">
                      It shall be the right and obligation of every Member Entitled to Vote
                      (MEV)to register and cast his/hervote and participate in any other
                      electoral proceeding/activity of the Cooperative.
                  </div>
                  <div class="py-2 font-bold">ARTICLE 5. – SCHEDULE OF ACTIVITIES</div>
                  <div class="py-2">
                      <b>Section 1.</b> Election Period. Election period shall commence upon the
                      posting of the notice of Election and ends upon completion of all
                      scheduled manner of election and formal proclamation of winning
                      candidates during the special board meeting. The notice of election may
                      be included in the notice of the GA, if the election will be held
                      simultaneously with the GA. The election period will depend on the
                      declared period of election by the ELECOM to meet the quorum of the
                      voting members.
                  </div>
                  <div class="py-2">
                      <b>Section 2.</b> Schedule of Election Activities. Following are the schedule
                      of election activities for the election of Board of Directors (BOD),
                      Audit and Supervisory Committee (ASC) and Election Committee (ELECOM).
                  </div>
                  <div class="py-2 font-bold">A.Pre-Election</div>
                  <div class="py-2">
                      <b>1.</b> Announcement of Vacancy - Position on the PAFCPIC BOD, ASC and ELECOM
                      for regular members to be vacated for the incoming period shall be
                      published/announced thru the Cooperative Secretary to all members
                      nationwide through a memorandum published by PAFCPIC main and satellite
                      offices.
                  </div>
                  <div class="py-2">
                      <b>2.</b> Availability of Representative Forms – Representative Forms shall be
                      made available to all members four (4) months prior to the last day of
                      acceptance of representative forms. Duly accomplished representative
                      forms shall be entertained and registered with the ELECOM.
                  </div>
                  <div class="py-2">
                    3. Filing of Certificate of Candidacy (COC) – Interested Cooperative
                    member shall file his/her COC and duly received by ELECOM or the
                    Cooperative Secretary will be honored not later than the close of office
                    hours on the last day of filing of candidacy. Filing of COCs can be
                    extended for a certain period of time ifcircumstances arise to justify
                    such extension. At no instance, however, shall it violate the right of
                    members who filed their candidacy before the expiration of the deadline
                    for the filing of COC.
                  </div>
                  <div class="py-2">
                    4. Publication of Qualified Candidates – ELECOM shall submit the names
                    of qualified candidates running for different positions tothe
                    Cooperative Secretary for publication.
                  </div>
                  <div class="py-2">
                    5. Representative Forms Validation – ELECOM shall appoint validation
                    clerks who will examine the duly-signedrepresentative forms and shall
                    supervise the process thereof. Validation clerks shall examine the
                    correctness of each representative forms from the cooperative database
                    in order to determine the name of MEVand the authenticity of
                    his/hercorresponding signature. Validated representative forms will be
                    counted as one vote. Invalid forms will be discarded automatically. Date
                    of validation shall be determined by the ELECOM.
                  </div>
                  <div class="py-2">
                    6. Campaign Period – The campaign period of the candidates shall be
                    determined by the ELECOM through publication. Last day of the campaign
                    shall be a day before the election date.
                  </div>
                  <div class="py-2">
                    7. Casting of Votes – Members can cast their votes in either of the
                    three (3) different modes or manners, such as Face-to-Face voting, PSO
                    On-Site Voting, and through Mobile App Voting. In Face-to-Face voting,
                    members registration shall be done by presenting the members cooperative
                    identification card. Registration committee shall determine if a member
                    is a representative vote holder or a walk-in voter. Walk-in voters and
                    representative vote holders can simultaneously vote within the
                    prescribed time to be announced by the ELECOM which shall commence
                    immediately after the approval of the Revised Election Code for CY 2022
                    during the general assembly. ELECOM members are allowed to represent any
                    vote as long as it is entrusted by the said member. For On- Site voting,
                    members can go directly to their designated PSOs and utilize the
                    designated voting station installed thereat. They will be assisted by
                    ELECOM Representative or Deputized Poll Clerk who will supervise and
                    oversee the conduct of the election. In Mobile Application voting,
                    members can also cast their votes by downloading the mobile app through
                    IOS App Store or Android Play Store or by simply clicking the link found
                    in the PAFCPIC web page redirecting the voter to download the mobile
                    app.Upon download, they will be prompted to register their identity
                    credentials which will be used as proof of legitimacy for their votes to
                    be counted and subsequently they can cast their votes.
                  </div>
                  <div class="py-2">
                    8. Counting and Canvassing of Votes – Counting and canvassing of votes
                    shall be done through a fast and secured electronic means.
                  </div>
                  <div class="py-2">
                    9. Publication of the Result of Canvassing of Votes – ELECOM Chairperson
                    shall publish the initial result of the election at the conclusion of
                    the GA Meeting and subsequently announce the winning candidates after
                    the election period has been completed.
                  </div>
                  <div class="py-2">
                    10. Voting Procedures – Voting shall only be done upon successful
                    registration either in person, through a representative holder or via
                    mobile app. Voting shall start as published by the ELECOM through the
                    Board Secretary. During the face-to-face voting, the Board Secretary
                    will turn over the floor to the ELECOM Chairperson to officially
                    announce the start of voting.
                  </div>
                  <div class="py-2">
                    The following are the voting procedures for walk-in and representative
                    vote holder voters:
                  </div>
                  <div>
                    a. Walk-in and representative holders shall present his/her cooperative
                    identification card or Military ID to the registration booth for
                    checking as to the MEV.
                  </div>
                  <div>
                    b. Voters shall proceed to the polling booths provided for FILLING UP
                    his/her ballot. Ballots must be filled up with THE names of THE CHOSEN
                    candidates.
                  </div>
                  <div>
                    c. He/she hands over the printed ballot to the poll clerk for his/her
                    signature on the DESIGNATED stub provided for IN THE BALLOT.
                  </div>
                  <div>d. The ballots shall be dropped in the designated ballot box.</div>
                  <div class="py-2">Voting procedures for PSO On-Site Voters:</div>
                  <div class="py-2">
                    a. On-Site voters shall present their cooperative identification card or
                    Military ID to the ELECOM representative or Poll Clerk in their designated
                    PSO for checking as to the MEV.
                  </div>
                  <div class="py-2">
                    b. After verification, voters will be assisted by Poll Clerks to enable
                    them to use the tablet provided for on-site voting and begin entering
                    identity credentials to successfully cast their votes.
                  </div>
                  <div class="py-2">Voting procedures for Mobile App Voters:</div>
                  <div>
                    a. Mobile App voters shall first download the PAFCPIC mobile app from the
                    Play Store or App Store of their Android or IOS smart phones. Upon
                    successful download, they will be prompted to sign in by entering their
                    identity credentials to determine authenticity and validity of voting
                    entitlement. They can also access the mobile app through the official web
                    page of PAFCPIC by clicking the link found therein.
                  </div>
                  <div>
                    b. Voters will receive a One-Time-Pin (OTP) to enable them to proceed to
                    casting of votes to their chosen candidates.
                  </div>
                  <div class="py-2">
                    B. Post-Election – Oath taking of winning candidates – 2ND Saturday of the
                    preceding week after the GA.
                  </div>
                  <div class="font-bold py-2">
                    ARTICLE 6. – QUALIFICATION AND DISQUALIFICATION OF CANDIDATES FOR BOARD OF
                    DIRECTORS (BOD), AUDIT AND SUPERVISORY COMMITTEE (ASC) & ELECTION
                    COMMITTEE (ELECOM)
                  </div>
                  <div class="py-2">
                    Section 3. Qualifications of a Candidate. Except for re-electionist,
                    ELECOM shall not accept, merit and consider any Certificate of Candidacy
                    (COC) of the candidates unless the applicant possesses the following
                    qualifications:
                  </div>
                  <div>
                    a. Must be a regular member entitled to votein the Cooperative; for the
                    Board of Directors, the candidates must have a Baccalaureate Degree. For
                    the Audit and Supervisory Committee, the candidate is preferred to have a
                    degree in accounting or has at least five (5) years experience of training
                    in related discipline;
                  </div>
                  <div>b. He/she must be of proven integrity, probity and honesty;</div>
                  <div>c. He/she must be of good moral character;</div>
                  <div>d. He/she has continuously patronized the cooperative services;</div>
                  <div>
                    e. For new candidates,he/she has completed or willing to complete the
                    required education and training as may be provided by laws, rules and
                    regulations and policy approved by the GA; and,
                  </div>
                  <div>f. He/she has the time and willingness to serve the Cooperative.</div>
                  <div>
                    g. He/she must have attended and completed the required Cooperative-
                    related seminars and trainings.
                  </div>
                  <div class="py-2">
                    Section 4. Disqualifications of a Candidate. Any regular member, who is
                    covered by the following circumstances, is disqualified to run for
                    elective position.
                  </div>
                  <div>
                    a. Those having conflict of interest with the business of the Cooperative
                  </div>
                  <div>
                    b. Those having been convicted of any crime involving moral turpitude,
                    gross negligence, or gross misconduct in the performance of their duties
                    or found culpable in any administrative case involving such offenses.
                  </div>
                  <div>c. Those full time employee/s of the PAFCPIC Cooperative.</div>
                  <div>
                    d. He/she holdsany elective position in the government, except that of a
                    cooperativeparty list representative, unless otherwise provided by law.
                  </div>
                  <div>
                    e. Those having served hereafter three (3) consecutive terms immediately
                    preceding the election. This requirement shall not be applicable for
                    candidates who were extended or retain their elected seats and remain in
                    their position in a hold-over capacity in CY 2021.
                  </div>
                  <div>
                    f. An incumbent member of any committee who files a Certificate of
                    Candidacy (COC) for higher office shall be deemed resigned from his
                    current position.
                  </div>
                  <div>
                    g. Those having been disqualified pursuant to disqualifications prescribed
                    by the Law or the CDA.
                  </div>
                  <div class="py-2">
                    Section 5. Mandatory Training. A candidate who was previously elected as
                    an officer of the Cooperative must have complied with the mandatory
                    training requirements during their term as provided for in CDA Memorandum
                    Circular 2015- 09.Non-compliance with the training requirements shall be
                    one of the grounds for disqualification of a candidate seeking re-election
                    until such time that he/she has complied with the required training. The
                    Cooperative shall ensure that all officers shall undertake/comply with the
                    mandatory training during their term.
                  </div>
                  <div class="py-2">
                    Section 6. Letter of Intent and Plan. Each and every candidate shall be
                    allowed to express his/her COC sent thru ELECOM or thru the Cooperative
                    Secretariat. The express plan of a candidate may be indicated in the
                    publication of the qualified candidate.
                  </div>
                  <div class="py-2">
                    Section 7. Disqualification Notice. Any member who filed his/her COC whose
                    application was not accepted, given merit and considered by the ELECOM or
                    has been disqualified, shall be notified at the soonest possible time of
                    his/her disqualification and the reasons thereof. The disqualified
                    candidate may appeal for his/her disqualification within five (5) days
                    from receipt of the notice of disqualification. The ELECOM shall decide on
                    the merit of the appeal within five (5) days upon receipt of the appeal.
                    The decision of the ELECOM is appealable to the Cooperative Development
                    Authority (CDA).
                  </div>
                  <div class="py-2">
                    Section 8. Withdrawal of Candidacy. Officially approved candidates can
                    withdraw from his or her candidacy by officially writing to the election
                    committee one (1) week before the scheduled election.
                  </div>
                  <div class="py-2 font-bold">ARTICLE 7. – VALIDATING CLERKS</div>
                  <div class="py-2">
                    Section 9. Appointment of Validating Clerk. The ELECOM may appoint Ten
                    (10) validating clerks to screen representative votes. Validating Clerks
                    shall not be an incumbent official of the cooperative nor are related to
                    any candidate seeking election.
                  </div>
                  <div class="py-2">
                    Section 10. Duties of Validating Clerks. The Validating Clerks shall have
                    the following duties under the supervision of the ELECOM:
                  </div>
                  <div>a. Validaterepresentative votes</div>
                  <div>b. Assist in receiving and validating representative votes</div>
                  <div>c. Assist in the conduct of the election</div>
                  <div>d. Assist in the canvassing of votes</div>
                  <div class="py-2 font-bold">ARTICLE 8. – POLL CLERKS</div>
                  <div class="py-2">
                    Section 11. Appointment of Poll Clerks. The ELECOM shall appoint ten (10)
                    Poll Clerks before the scheduled election. However, in case that voting
                    will also be done in designated PSOs for On-site voting, ELECOM may
                    appoint additional Poll Clerks to assist in the conduct of elections at
                    PSOs. Poll Clerks as deputized by ELECOM may act as ELECOM Representative
                    and shall exercise fairness and equality throughout the conduct of the
                    election period. Poll Clerks shall not be an incumbent official of the
                    Cooperative nor are related to any candidate seeking election. The ELECOM
                    shall exercise over-all control and authority over the appointed Poll
                    Clerks. It may overrule any action/decision of the designated Poll Clerks,
                    if warranted.
                  </div>
                  <div class="py-2">
                    Section 12. Duties of Poll Clerks. The poll clerks shall have the
                    following duties under the supervision of the ELECOM:
                  </div>
                  <div>a. Assist in the conduct of election</div>
                  <div>
                    b. See to it that election is conducted in an orderly manner and in
                    accordance with the minimum standard health protocols set forth by the
                    IATF-MEID.
                  </div>
                  <div>c. Assist the voters during the election proper</div>
                  <div>d. Control the movement of voters for smooth election</div>
                  <div class="py-2 font-bold">ARTICLE 9. – FUNCTION AND DUTIES</div>
                  <div class="py-2 pl-2 font-bold">
                    Section 13. Function and Duties of ELECOM:
                  </div>
                  <div>
                    a. Formulate election rules and guidelines and recommend to the GA for
                  </div>
                  approval
                  <div>
                    b. Implement election rules and guidelines duly approved by the GA
                  </div>
                  <div>
                    c. Recommend necessary amendments to the election rules and guidelines, in
                    consultation with the BOD, for approval bythe GA
                  </div>
                  <div>d. Supervise the validation of representative votes</div>
                  <div>e. Canvass and certify the results of the election</div>
                  <div>
                    f. Enforce and administer all proceedings relative to the conduct of
                    elections for the purpose of enduring free, orderly, and honest elections.
                  </div>
                  <div>g. Proclaim the winning candidates.</div>
                  <div>
                    h. Decide election and other related cases except those involving the
                    Election committee or its members.
                  </div>
                  <div>
                    i. Perform such other functions as prescribed in the PAFCPIC By-Laws.
                  </div>
                  <div class="py-2 pl-2 font-bold">ARTICLE 10. – RULES OF VOTING</div>
                  <div class="py-2">
                    Section 14. Rules of Voting. Guidelines in the casting of votes during the
                    elections.
                  </div>
                  <div>
                    a. The election of the BOD and other elective officers shall be held in
                    accordance with the By-Laws of the Cooperative.
                  </div>
                  <div>
                    b. All members in good standing shall be entitled to one (1) vote
                    regardless of the number of shares subscribed or paid with the
                    Cooperative. However, representative voting shall be allowed and permitted
                    which means that a member may delegate to any member entitled to vote in
                    his/her behalf on all matters expressly stated in the authorization as
                    representative.
                  </div>
                  <div>
                    c. No single member shall be allowed to register and vote more than 100
                    member votes including that of his/her own. Representative votes shall be
                    valid only for an election that is being held for which it was made.
                  </div>
                  <div>
                    d. Election shall be done through secured electronic voting for the
                    faster, safer, credible, and efficient casting of votes.
                  </div>
                  <div>
                    e. The election shall be conducted in a practical, convenient, and most
                    economical manner. Votes shall be cast without undue
                    intimidation/influence from the candidates or any person related to them.
                    Campaign paraphernalia/ materials shall be limited at the most to one
                    coupon bond size only, to be displayed in the designated area. No streamer
                    and other forms of campaign gimmicks shall be allowed to be posted or
                    conducted in the polling booths. As a Cooperative, it is the duty of every
                    member-cooperator to uphold the integrity of the electoral process.
                  </div>
                  <div>
                    f. Voting during the Regular GA shall be held annually within ninety (90)
                    days after the close of its fiscal year, within its area of operation and
                    such time and date as the Board may designate.
                  </div>
                  <div class="py-2 pl-2 font-bold">ARTICLE 11. COUNTING & CANVASSING.</div>
                  <div class="py-2">
                    Section 15. Counting & Canvassing. Counting and Canvassing of votes shall
                    commence after the Chairperson, ELECOM announces the end of the casting of
                    votes. Votes shall be counted and securely transmitted electronically to
                    the main server for consolidation. The number of votes for each candidate
                    will be electronically generated and the results of the election shall be
                    known immediately.
                  </div>
                  <div class="py-2 pl-2 font-bold">ARTICLE 12. – PROCLAMATION OF WINNERS</div>
                  <div class="py-2">
                    Section 16. Proclamation of Winners. The winner/s of the election shall be
                    proclaimed by the Chairperson of the ELECOM upon completion of the
                    election period or as decided by the BOD without prejudice to the election
                    protest filed against the winner.
                  </div>
                  <div class="py-2">
                    Section 17. Publication of Votes Received. The Chairperson of the ELECOM
                    shall make known upon completion of the election periodthe number of votes
                    garnered by the winners from the highest to the lowest.
                  </div>
                  <div class="py-2">
                    Section 17. Publication of Votes Received. The Chairperson of the ELECOM
                    shall make known upon completion of the election periodthe number of votes
                    garnered by the winners from the highest to the lowest.
                  </div>
                  <div class="py-2">
                    Section 19. Disposition of Votes. Immediately after the protest period and
                    the newly elected officers have taken their oath, all paraphernalia used
                    during the exercise shall be placedunder the custodyof the Board Secretary
                    who shall dispose of the same within a period of months after being
                    satisfied that there is no pending controversy before the CDA.
                  </div>
                  <div class="py-2 font-bold">ARTICLE 13. – ELECTION CONTESTS.</div>
                  <div class="py-2">
                    Section 20. Period of Filing Election Protests. Sworn protest must be
                    accomplished in writing and may be filed at any stage of the election
                    proceedings but not beyond twenty-four (24) hours after the proclamation
                    of the winning candidates. The protest shall state therein, the grounds
                    and evidence whatsoever for deliberation and consideration by the ELECOM.
                    The protesting party shall shoulder the expenses involved therein.
                    Protests filed beyond the twenty-four (24) hours period after the
                    proclamation of winning candidates may be entertained by the ELECOM for
                    the imposition of appropriate administrative and corrective measures but
                    shall no longer affect the result of the election. All protests shall be
                    immediately acted upon by the ELECOM whose decision shall be final if not
                    seasonablyappealed to the CDA within fifteen (15) days after receipts of
                    the ELECOM/BODdecision.
                  </div>
                  <div class="py-2 font-bold">ARTICLE 14. OATH OF OFFICE</div>
                  <div class="py-2">
                    Section 21. Date of Oath Taking. Proclamation of winners shall be held
                    during the Special Board Meeting called for the purpose. The decision of
                    the ELECOM shall be final. The date of the oath-taking of newly elected
                    Officers shall be on the succeeding week after the electionperiod. In the
                    event of unforeseen circumstances, oath-taking will be moved to a date
                    determined by the BOD.
                  </div>
                  <div class="py-2 font-bold">ARTICLE 15. ASSUMPTION AND TERM OF OFFICE</div>
                  <div class="py-2">
                    Section 22. Assumption of Office. Immediately after the oath-taking, the
                    newly elected officers shall assume office.
                  </div>
                  <div class="py-2">
                    Section 23. Term of Office. The term of office of the newly elected
                    officers shall be two(2) years as provided by the By-Laws unless
                    terminated earlier for cause, or have resigned or become incapacitated due
                    to illness or death, and until their successor have been elected,
                    qualified and have discharged the duties of the office, provided that no
                    elected officers shall serve for more than three (3) consecutive terms. In
                    case of the elected ELECOM members, they shall not seek re-election or run
                    for another elected position during his/her incumbency. However, in the
                    upcoming General Assembly and Election IN CY 2022, the top eight (8)
                    candidates for Board of Directors with a minimum category composition of
                    three (3) Officers, three (3) Enlisted Personnel and two (2) Civilian who
                    garnered the highest number of votes shall serve for two (2) years while
                    those remaining seven (7) candidates who got the next highest number of
                    votes shall only serve for one (1) year or till the next commencement of
                    election. Similar condition applies to the ELECOM members wherein the
                    three (3) candidates who got most votes shall serve for two (2) years and
                    the remaining two (2) candidates who got the next highest number of votes
                    shall only serve for one (1) year. Thereafter, the selection of Officers
                    and Committee members will return to its normal procedure as provided in
                    the PAFCPIC By-Laws.
                  </div>
                  <div class="py-2 font-bold">
                    ARTICLE 16. REPEALING CLAUSE AND ADDITIONAL AUTHORITY.
                  </div>
                  <div class="py-2">
                    Section 24. Applicability. This 2022 Revised PAFCPIC Election Code is
                    applicable until such time that the same or any of its provisions has been
                    amended, repealed or modified accordingly.
                  </div>
                  <div class="py-2">
                    Section 25. Repealing Clause. The Revised PAFCPIC Election Code of 2016,
                    The Election Code of the Philippine Army Finance Center Producers
                    Cooperative (PAFCPIC) of 1994 and the revised Election Code of PAFCPIC
                    2001 together with their implementing rules and regulations inconsistent
                    with this Code are herebyrescinded.
                  </div>
                  <div class="py-2">
                    Section 26. Additional Authority. In case of doubt as to the meaning of
                    any provision of this Code and/or the Implementing Rules and Regulations
                    issued in pursuance thereof, the same shall be resolved liberally in favor
                    of the intent and spirit of upholding the electoral processes. Nothing
                    herein shall preclude the PAFCPIC ELECOM from promulgating additional
                    rules, with due notice, in order to promote a fair and just election.
                  </div>
                  <div class="py-2 font-bold">ARTICLE 17. EFFECTIVITY AND PUBLICATION.</div>
                  <div class="py-2 pb-6">
                    Section 27. Effectivity and Publication. This Revised PAFCPIC Election
                    Code 2022 shall take effect as amended and revised by the ELECOM upon
                    approval by the General Assembly. A copy thereof shall be published in the
                    PAFCPIC official publication and social media accounts for
                    widestdissemination to the entire members.
                  </div>
                  </div>
                  <div>
                      <ButtonControl v-on:click="start">Continue</ButtonControl>
                  </div>
            </div>
        </ContentWrapper>
    </div>
</template>

<script>
import ButtonControl from './../../components/controls/ButtonControl.vue';
import ContentWrapper from './../../components/controls/ContentWrapperControl.vue';
import Election from './../../services/Election.js';
import MessageBoxControl from './../../components/controls/MessageBoxControl.vue';
import OTPModalControl from './../../components/controls/OTPModalControl.vue';
import mixin from './../../mixin.js';

export default {
    mixins: [
      mixin,
    ],
    components: {
        ButtonControl,
        ContentWrapper,
        MessageBoxControl,
        OTPModalControl,
    },
    data() {
        return {
            display: false,
            message: '',
            theme: 'error',
            otp: null,
            pin: null,
            otp_show: false,
            otp_type: 5,
            name: null,
            mobile: null,
            email: null,
            show_message: true,
        }
    },
    mounted() {
        this.$emit('checkauth');
        this.$emit('nofooter', false);

        let title = this.lang('election.policy');

        this.$emit('shownavbar', {
            type: 'normal',
            title: title,
            show: true,
            back: true,
        });

        if(!this.$store.state.election.registered || this.$store.state.election.voted) {
            this.$router.push('/dashboard');
        }
        else {
            let state = this.$store.state;

            this.name = state.first_name;
            this.email = state.email;
            this.mobile = state.mobile;
        }

        let that = this,
            t = setTimeout(function() {
                that.show_message = false;
                clearTimeout(t);
        }, 5000);
    },
    methods: {
        start() {
            let that = this;

            that.$emit('showloader');
            Election.start(this.$store.state.uuid).then(function() {
                that.otp_show = true;
                that.$emit('hideloader');
            })
            .catch(function(error) {
              let status = error.response.status,
                  message = error.response.data.message;

                if(status != 403) {
                  that.theme = 'error';
                  that.message = message;
                  that.display = true;
                  that.$emit('hideloader');
                }
                else {
                  that.$emit('terminate', status);
                }
            });
        },
        closed() {
            this.display = false;
        },
        hideLoader() {
            this.$emit('hideloader');
        },
        showLoader() {
            this.$emit('showloader');
        },
        resend(otp) {
            this.otp = otp;
        },
        matches() {
            this.$store.state.election.started = true;
            this.$router.push('/election/vote/bod');
        },
        cancel() {
            this.otp_show = false;
        },
    }
}
</script>

<style scoped>
.papsi-bg-coffee-trans {
    background-color: rgba(0, 0, 0, 0.90);
}
</style>