<template>
    <ContentWrapperControl class="mt-4">
        <div class="bg-white shadow rounded p-4">
            <div class="mb-4">How do you feel about this election?</div>
            <div class="pb-4">
                <RatingControl @toggled="toggled" @submitted="exit" v-bind:submit="submit" :type="1" />
            </div>
            <div>
                <ButtonControl v-on:click="goSubmit" v-bind:disabled="disabled">Submit</ButtonControl>
            </div>
            <div class="pt-4 text-center text-md">
                or <span v-on:click="exit" class="text-primary">Skip</span>
            </div>
        </div>
    </ContentWrapperControl>
</template>

<script>
import ButtonControl from './../../components/controls/ButtonControl.vue';
import ContentWrapperControl from '../../components/controls/ContentWrapperControl.vue';
import RatingControl from '../../components/controls/RatingControl.vue';

export default {
    components: {
        ButtonControl,
        ContentWrapperControl,
        RatingControl,
    },
    data() {
        return {
            disabled: true,
            submit: false,
        }
    },
    mounted() {
        this.$emit('checkauth');
        this.$emit('nofooter', true);
        this.$emit('shownavbar', {
            type: 'normal',
            title: 'Thank You!',
            show: true,
            back: false,
        });

        let state = this.$store.state.election;

        if(!state.voted) {
            this.$router.push('/dashboard');
        }
    },
    methods: {
        toggled() {
            this.disabled = false;
        },
        goSubmit() {
            this.submit = true;
            this.$emit('showloader');
        },
        exit() {
            this.$emit('hideloader');
            this.$router.push('/dashboard');
        }
    }
}
</script>