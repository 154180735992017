<template>
    <div>
        <PrompControl @ok="ok"
            @cancel="cancel"
            @closed="closed"
            v-bind:show="prompt.show"
            v-bind:theme="prompt.theme"
            v-bind:title="prompt.title"
            v-bind:message="prompt.message" />
        <div class="px-4 border-b bg-yellow-lighter">
            <div class="py-4 text-sm leading-normal">
                It seems that you have created your account in one of our PAPSI Mobile express registration booth in PAFCPIC General Assembly.
                Please provide any of the following information below to continue to your account.
            </div>
        </div>
        <ContentWrapperControl class="mt-4">
            <div class="bg-white p-4 rounded shadow mb-4">
                <div v-text="name" class="text-md font-bold pb-2 border-b"></div>
                <div class="text-sm text-grey-darker pt-2 flex">
                    <div class="flex-grow">Registration Date</div>
                    <div v-text="$store.state.registered"></div>
                </div>
            </div>
            <SettingCardControl v-bind:title="lang('contacts')">
                <SettingCardItemControl v-bind:value="mobile"
                    v-bind:title="lang('mobile')"
                    icon="papsi_setting_mobile.svg"
                    href="/settings/mobile" />
                <SettingCardItemControl v-bind:value="email"
                    v-bind:title="lang('email')"
                    icon="papsi_setting_email.svg"
                    href="/settings/email" />
            </SettingCardControl>
            <div class="flex">
                <div class="flex-1 mr-2">
                    <ButtonControl v-on:click="verify" v-bind:disabled="disabled">
                        {{ lang('continue') }}
                    </ButtonControl>
                </div>
                <div class="flex-1">
                    <ButtonControl v-on:click="logout" theme="error">
                        {{ lang('log.out') }}
                    </ButtonControl>
                </div>
            </div>
        </ContentWrapperControl>
    </div>
</template>

<script>
import ButtonControl from './../../components/controls/ButtonControl.vue';
import ContentWrapperControl from './../../components/controls/ContentWrapperControl.vue';
import PrompControl from './../../components/controls/PromptControl.vue';
import User from './../../services/User.js';
import SettingCardControl from './../../components/controls/SettingCardControl.vue';
import SettingCardItemControl from './../../components/controls/SettingCardItemControl.vue';
import mixin from './../../mixin.js';

export default {
    mixins: [
        mixin,
    ],
    components: {
        ButtonControl,
        ContentWrapperControl,
        PrompControl,
        SettingCardControl,
        SettingCardItemControl,
    },
    data() {
        return {
            mobile: null,
            email: null,
            disabled: true,
            name: '',
            prompt: {
                show: false,
                theme: 'success',
                title: '',
                message: '',
            },
        }
    },
    mounted() {
        let title = this.lang('settings');

        this.$emit('checkauth');
        this.$emit('nofooter', true);
        this.$emit('shownavbar', {
            title: title,
            show: true,
            back: false,
        });

        this.name = this.$store.state.first_name + ' ' + this.$store.state.last_name;
        this.mobile = this.$store.state.mobile;
        this.email = this.$store.state.email;

        if(!this.$store.state.express) {
            this.$router.push('/dashboard');
        }

        this.disabled = (this.$store.state.mobile == null && this.$store.state.email == null);
    },
    methods: {
        verify() {
            let that = this;

            that.$emit('showloader');
            User.verify(this.$store.state.uuid).then(function() {
                that.$store.state.express = false;
                that.$router.push('/dashboard');
                that.$emit('hideloader');
            })
            .catch(function(error) {
                let status = error.response.status;

                if(error.response.status != 403) {
                    that.$emit('hideloader');
                }
                else {
                    that.$emit('terminate', status);
                }
            });
        },
        logout() {
            this.prompt.title = this.lang('log.out');
            this.prompt.message = this.lang('are.you.sure.you.want.to.leave');
            this.prompt.show = true;
        },
        ok() {
            let that = this,
                state = this.$store.state;

            that.$emit('showloader');
            User.logout(state.uuid).then(function() {
                that.$cookies.remove('hash');
                that.resetUserData();
                that.$emit('logout');
                that.$emit('hideloader');
                that.$router.push('/');
            }).catch(function() {
                that.$emit('terminate', 403);
            });
        },
        cancel() {
            this.prompt.show = false;
        },
        closed() {
            this.prompt.show = false;
        }
    }
}
</script>