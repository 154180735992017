<template>
    <div class="bg-white shadow rounded mb-3 overflow-hidden">
        <div v-on:click="back" v-bind:class="[ color ]" class="flex bg-primary py-2 px-3">
            <div class="flex-grow truncate">
                <div v-text="title" class="text-sm font-bold truncate text-white uppercase papsi-vote-card-title"></div>
                <div v-show="type != null" v-text="type" class="text-xs text-white opacity-75"></div>
            </div>
            <div v-show="!noedit" class="pl-3">
                <img class="h-icon" src="@/assets/icons/papsi_pencil.svg" />
            </div>
        </div>
        <div class="p-2">
            <VoteCardItemControl v-for="(item, index) in data" 
                v-bind:index="index"
                v-bind:key="item"
                v-bind:candidate="item"
                v-bind:id="id" />
        </div>
    </div>
</template>

<script>
import VoteCardItemControl from './../controls/VoteCardItemControl.vue';

export default {
    name: 'v-vote-card',
    components: {
        VoteCardItemControl,
    },
    props: {
        id: {
            type: String,
        },
        title: {
            type: String,
        },
        type: {
            type: String,
            default: null,
        },
        href: {
            type: String,
            default: null,
        },
        noedit: {
            type: Boolean,
            default: false,
        },
        update: {
            type: Boolean,
            default: false,
        },
        color: {
            type: String,
            default: 'bg-theme-bod',
        },
    },
    data() {
        return {
            data: [],
        }
    },
    mounted() {
        this.reconstruct();
    },
    methods: {
        back() {
            if(!this.noedit && this.href != null) {
                this.$router.push(this.href);
            }
        },
        reconstruct() {
            let store = this.$store.state.selected[this.id],
                type = this.type.toLowerCase(),
                that = this;

                this.data.splice(0, this.data.length);

                if(this.id == 'bod') {
                    let data;

                    if(type == 'officer') {
                        data = that.getByCategory(1, store);
                    }
                    else if(type == 'enlisted personnel')
                    {
                        data = that.getByCategory(2, store);
                    }
                    else {
                        data = that.getByCategory(3, store);
                    }

                    data.forEach(function(item) {
                        that.data.push(item);
                    });
                }
                else {
                    store.forEach(function(item) {
                        that.data.push(item);            
                    });
                }
        },
        getByCategory(type, selected) {
            let store = this.$store.state.candidates.bod,
                data = [];

            store.forEach(function(item) {
                if(item.type == type && selected.indexOf(item.key) != -1) {
                    data.push(item.key);
                }
            });

            return data;
        }
    },
    watch: {
        update() {
            this.reconstruct();
        }
    }
}
</script>

<style scoped>
.papsi-vote-card-title {
    line-height: 24px;
}
</style>