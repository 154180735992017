<template>
    <ContentWrapperControl class="mt-4">
        <div class="bg-white p-4 rounded shadow">
            <AlertControl @closes="closed" 
                v-bind:show="display" 
                v-bind:theme="theme" 
                autoclose>
                    <span v-text="message"></span>
            </AlertControl>
            <div class="mb-3">
                <TextControl @typing="typing" 
                    v-bind:value="tin" 
                    name="tin" 
                    type="text" 
                    :maxlength="11" />
            </div>
            <div>
                <ButtonControl v-on:click="update" v-bind:disabled="disabled">
                    {{ lang('update') }}
                </ButtonControl>
            </div>
        </div>
    </ContentWrapperControl>
</template>

<script>
import AlertControl from './../../components/controls/AlertControl.vue';
import ButtonControl from './../../components/controls/ButtonControl.vue';
import ContentWrapperControl from './../../components/controls/ContentWrapperControl.vue';
import TextControl from './../../components/controls/TextControl.vue';
import User from './../../services/User.js';
import mixin from './../../mixin.js';

export default {
    mixins: [
        mixin,
    ],
    components: {
        AlertControl,
        ButtonControl,
        ContentWrapperControl,
        TextControl,
    },
    data() {
        return {
            theme: 'error',
            display: false,
            message: '',
            changed: false,
            disabled: true,
            tin: '',
        }
    },
    mounted() {
        this.$emit('checkauth');
        this.$emit('nofooter', false);

        let title = this.lang('edit.tin');

        this.$emit('shownavbar', {
            title: title,
            show: true,
            back: true,
        });

        let store = this.$store.state,
            tin = store.tin;

        this.tin = tin;
    },
    methods: {
        typing(n) {
            this.tin = n.value;

            if(this.tin.length == 3 || this.tin.length == 7) {
                this.tin += '-';
            }

            this.disabled = this.tin.length != 11 || (this.tin == this.$store.state.tin);
        },
        update() {
            let that = this;
            this.$emit('showloader');

            let split = this.tin.split('-');

            User.updateTin(this.$store.state.uuid, split[0], split[1], split[2]).then(function() {
                that.$store.state.tin = that.tin;
                that.theme = 'success';
                that.display = true;
                that.message = 'TIN was successfully updated.';
                that.disabled = true;
                that.$emit('hideloader');
            }).catch(function(error) {
                let status = error.response.status,
                    message = error.response.data.message;

                if(status != 403) {
                    that.theme = 'error';
                    that.display = true;
                    that.disabled = true;
                    that.message = message;
                    that.$emit('hideloader');
                }
                else {
                    that.$emit('terminate', status);
                }
            });
        },
        closed() {
            this.display = false;
        },
    },
    watch: {
        tin(val) {
            this.changed = val != this.$store.state.tin;
        }
    },
}
</script>