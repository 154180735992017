<template>
    <div>
        <PromptControl @closed="promptClosed"
            @ok="ok"
            @cancel="cancel"
            v-bind:show="show" 
            v-bind:title="lang('discard.changes')" 
            v-bind:message="lang('do.you.want.to.discard.all.changes')" />
        <ContentWrapperControl class="mt-4">
            <div class="bg-white p-4 rounded shadow">
                <AlertControl @closes="closed"
                    v-bind:show="display" 
                    v-bind:theme="theme" 
                    autoclose>
                    {{ message }}
                </AlertControl>
                <div>
                    <div class="mb-4">
                        <div>
                            <TextControl v-bind:value="value" @typing="typing" type="text" name="username" />
                        </div>
                        <div v-text="lang('username.must.be.at.least.six.characters.and.must.not.contain.spaces.and.special.characters')" class="mt-1 text-xs text-grey-dark italic mb-4"></div>
                    </div>
                    <div>
                        <ButtonControl v-on:click="update" v-bind:disabled="disabled">
                            {{ lang('update') }}
                        </ButtonControl>
                    </div>
                </div>
            </div>
        </ContentWrapperControl>
    </div>
</template>

<script>
import { onBeforeRouteLeave } from 'vue-router';
import AlertControl from './../../components/controls/AlertControl.vue';
import ButtonControl from './../../components/controls/ButtonControl.vue';
import ContentWrapperControl from './../../components/controls/ContentWrapperControl.vue';
import PromptControl from './../../components/controls/PromptControl.vue';
import TextControl from './../../components/controls/TextControl.vue';
import User from './../../services/User.js';
import mixin from './../../mixin.js';

export default {
    mixins: [
        mixin,
    ],
    components: {
        AlertControl,
        ButtonControl,
        ContentWrapperControl,
        PromptControl,
        TextControl,
    },
    data() {
        return {
            theme: 'error',
            value: '',
            disabled: true,
            display: false,
            changed: false,
            href: null,
            message: '',
            show: false,
        }
    },
    mounted() {
        this.$emit('checkauth');
        this.$emit('nofooter', false);

        let title = this.lang('edit.username');

        this.$emit('shownavbar', {
            title: title,
            show: true,
            back: true,
        });

        let that = this;

        onBeforeRouteLeave((to) => {
            that.href = to.fullPath;

            if(that.changed) {
                that.show = true;

                return false;
            }
        });

        this.value = this.$store.state.username;
    },
    methods: {
        ok() {
            this.changed = false;
            this.$router.push(this.href);
        },
        cancel() {
            this.show = false;
        },
        promptClosed() {
            this.show = false;
        },
        closed() {
            this.display = false;
        },
        typing(obj) {
            this.value = obj.value;

            this.disabled = this.value == 0 || (this.value == this.$store.state.username);
        },
        update() {
            this.$emit('showloader');
            
            let that = this,
                state = this.$store.state,
                uuid = state.uuid;

            User.updateUsername(uuid, this.value).then(function() {
                that.$store.state.username = that.value;
                that.theme = 'success';
                that.display = true;
                that.changed = false;
                that.disabled = true;
                that.message = that.lang('username.was.successfully.updated');
                that.$emit('hideloader');
            }).catch(function(error) {
                let status = error.response.status,
                    message = error.response.data.message;

                if(status != 403) {
                    that.theme = 'error';
                    that.display = true;
                    that.disabled = true;
                    that.message = message;
                    that.$emit('hideloader');
                }
                else {
                    that.$emit('terminate', status);
                }
            });
        }
    },
    watch: {
        value(val) {
            this.changed = val != this.$store.state.username;
        },
    }
}
</script>