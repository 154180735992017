<template>
    <div>
        <VoteSection @preload="preload"
            @loaded="loaded"
            :max="1"
            id="elecom_ci"
            category="elecom"
            type="ci" 
            title="Election Committee"
            subtitle="Civilian"
            notice="Please select 1 Civilian Candidate for Election Committee."
            href="/election/vote/audit"
            color="bg-theme-elecom"
            backable />
    </div>
</template>

<script>
import VoteSection from './../../components/sections/ElectionVoteSection.vue';

export default {
    components: {
        VoteSection,
    },
    mounted() {
        this.$emit('checkauth');
        this.$emit('nofooter', true);
        this.$emit('shownavbar', {
            show: false,
        });

        let state = this.$store.state.election;

        if(!state.registered || state.voted) {
            this.$router.push('/dashboard');
        }
    },
    methods: {
        preload() {
            this.$emit('showloader');
        },
        loaded() {
            this.$emit('hideloader');
        }
    }
}
</script>