import service from './../service.js';

export default {
    register(uuid) {
        return service.post('/election/' + uuid + '/register');
    },

    start(uuid) {
        return service.post('/election/' + uuid + '/start');
    },

    candidates(category, type = null) {
        let url = '/election/candidates/' + category;

        if(type != null) {
            url += '/' + type;
        }

        return service.get(url);
    },

    vote(uuid, data = []) {
        return service.post('/election/' + uuid + '/vote', {
            data: data,
        });
    },

    getBallot(ballot) {
        return service.get('/election/ballot/' + ballot);
    },

    validateOtp(uuid, otp) {
        return service.post('/election/otp', {
            uuid: uuid,
            otp: otp,
        });
    },
}