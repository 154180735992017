<template>
    <div>
        <div class="w-full h-screen flex flex-col">
            <div class="bg-primary">
                <div class="pt-3 px-3 flex">
                    <div class="h-menu w-menu"></div>
                    <div class="flex-grow text-center pt-6 pb-3">
                        <div class="text-xl text-white font-bold uppercase pt-6">
                            SUMMARY
                        </div>
                    </div>
                    <div class="h-menu w-menu"></div>
                </div>
                <div class="max-w-limit w-full mx-auto">
                    <div class="px-4">
                        <div class="bg-white py-2 w-full rounded-t"></div>
                    </div>
                </div>
            </div>
            <div class="flex-grow w-full overflow-auto pb-4">
                <ContentWrapper>
                    <div v-if="!loaded" class="bg-white shadow border-b p-6 rounded-b">
                        <img v-bind:src="getLoader()" class="block mx-auto" />
                    </div>
                    <div v-if="loaded" class="bg-white shadow border-b p-6 text-sm leading-normal rounded-b" style="font-family: Courier New, Courier, monospace">
                        <div class="w-full flex justify-center items-center mb-6">
                            <img v-bind:src="getLogo()" style="width: 60px" />
                        </div>
                        <div class="text-center text-lg font-bold uppercase">Philippine Army Finance Center Producers Integrated Cooperative</div>
                        <div class="uppercase border-t-2 border-black mt-6">
                            <div class="mt-6">Ballot No.: <span v-text="ballot"></span></div>
                            <div>Name: <span v-text="name"></span></div>
                            <div>Account No.: <span v-text="account_no"></span></div>
                            <div>Serial No.: <span v-text="serial_no"></span></div>
                            <div>Number of Votes: <span v-text="count"></span></div>
                        </div>
                        <div class="border-t-2 border-black mt-6"></div>
                        <div class="border-t border-black" style="margin-top: 2px;"></div>
                        <div class="py-6">
                            <div>
                                <div class="font-bold text-md uppercase mb-6">I. Board of Directors</div>
                                <div class="text-sm uppercase underline truncate">Officer</div>
                                <div class="mt-3 px-2">
                                    <div v-for="(item, index) in bod_of" v-bind:key="index" class="truncate text-xs"><span>{{ index + 1 }}.</span> <span v-text="item"></span></div>
                                </div>
                            </div>
                            <div class="mt-3">
                                <div class="text-sm uppercase underline truncate">Enlisted Personnel</div>
                                <div class="mt-3 px-3">
                                    <div v-for="(item, index) in bod_ep" v-bind:key="index" class="truncate text-xs"><span>{{ index + 1 }}.</span> <span v-text="item"></span></div>
                                </div>
                            </div>
                            <div class="mt-3">
                                <div class="text-sm uppercase underline truncate">Civilian</div>
                                <div class="mt-3 px-3">
                                    <div v-for="(item, index) in bod_ci" v-bind:key="index" class="truncate text-xs"><span>{{ index + 1 }}.</span> <span v-text="item"></span></div>
                                </div>
                            </div>
                            <div class="mt-6 border-t-2 border-black pt-4">
                                <div class="font-bold text-md uppercase mb-6">II. Election Committee</div>
                                <div class="text-sm uppercase underline truncate">Officer</div>
                                <div class="mt-3 px-3">
                                    <div v-for="(item, index) in elecom_of" v-bind:key="index" class="truncate text-xs"><span>{{ index + 1 }}.</span> <span v-text="item"></span></div>
                                </div>
                            </div>
                            <div class="mt-3">
                                <div class="text-sm uppercase underline truncate">Enlisted Personnel</div>
                                <div class="mt-3 px-3">
                                    <div v-for="(item, index) in elecom_ep" v-bind:key="index" class="truncate text-xs"><span>{{ index + 1 }}.</span> <span v-text="item"></span></div>
                                </div>
                            </div>
                            <div class="mt-3">
                                <div class="text-sm uppercase underline truncate">Civilian</div>
                                <div class="mt-3 px-3">
                                    <div v-for="(item, index) in elecom_ci" v-bind:key="index" class="truncate text-xs"><span>{{ index + 1 }}.</span> <span v-text="item"></span></div>
                                </div>
                            </div>
                            <div class="mt-6 border-t-2 border-black pt-4">
                                <div class="font-bold text-md uppercase truncate mb-6">III. Audit & Supervisory Committee</div>
                                <div class="mt-3 px-3 text-sm">
                                    <div v-for="(item, index) in audit" v-bind:key="index" class="truncate text-xs"><span>{{ index + 1 }}.</span> <span v-text="item"></span></div>
                                </div>
                            </div>
                        </div>
                        <div class="border-t-2 border-black"></div>
                        <div class="pt-6 text-xs uppercase">
                            <div class="flex">
                                <div class="flex-grow">Registered:</div><div v-text="registered"></div>
                            </div>
                            <div class="flex">
                                <div class="flex-grow">Started:</div><div v-text="started"></div>
                            </div>
                            <div class="flex pb-6">
                                <div class="flex-grow">Submitted:</div><div v-text="voted"></div>
                            </div>
                        </div>
                    </div>
                    <div ref="footerspacer"></div>
                </ContentWrapper>
            </div>
        </div>
        <div ref="footerbar" class="p-2 bg-white border-t w-full fixed z-footer papsi-ballot-footer">
            <ButtonControl v-on:click="exit" v-bind:disabled="!loaded" theme="error">
                Proceed
            </ButtonControl>
        </div>
    </div>
</template>

<script>
import ButtonControl from './../../components/controls/ButtonControl.vue';
import ContentWrapper from './../../components/controls/ContentWrapperControl.vue';
import Election from './../../services/Election.js';
import mixin from './../../mixin.js';

export default {
    mixins: [
        mixin,
    ],
    components: {
        ButtonControl,
        ContentWrapper,
    },
    data() {
        return {
            loaded: false,
            ballot: '',
            count: 1,
            name: '',
            account_no: '',
            serial_no: '',
            registered: null,
            started: null,
            voted: null,
            votes: [],
            bod_of: [],
            bod_ep: [],
            bod_ci: [],
            elecom_of: [],
            elecom_ep: [],
            elecom_ci: [],
            audit: [],
        }
    },
    mounted() {
        this.$emit('checkauth');
        this.$emit('nofooter', true);

        if(this.$store.state.election.ballot != null) {
            this.load();
        }

        this.setSpacer();
    },
    methods: {
        setSpacer() {
            this.$refs.footerspacer.style.height = (parseInt(this.$refs.footerbar.offsetHeight) + 20) + "px";
        },
        getLogo() {
            return this.getMediaPath('/logo/pafcpic.png');
        },
        load() {
            let that = this;

            Election.getBallot(this.$store.state.election.ballot).then(function(response) {
                let data = response.data.data;
                
                that.ballot = data.ballot;
                that.count = data.count;
                that.name = data.user.name;
                that.account_no = data.user.account_no;
                that.serial_no = data.user.serial_no;
                that.registered = data.dates.registered;
                that.started = data.dates.started;
                that.voted = data.dates.casted;

                data.votes.forEach(function(item) {
                    that.votes.push(item);
                });

                that.loaded = true;
                that.setBODCandidates(that.votes, 'bod_of', 1);
                that.setBODCandidates(that.votes, 'bod_ep', 2);
                that.setBODCandidates(that.votes, 'bod_ci', 3);
                that.setCandidates(that.votes, 'elecom_of');
                that.setCandidates(that.votes, 'elecom_ep');
                that.setCandidates(that.votes, 'elecom_ci');
                that.setCandidates(that.votes, 'audit');
                that.$emit('hideloader');
            });
        },
        setBODCandidates(data, name, type) {
            let candidates = this.$store.state.candidates.bod,
                that = this,
                list = [];

            candidates.forEach(function(item) {
                let key = that.getCandidateCategory(item.key);

                if(data.indexOf(item.key) != -1 && type == key) {
                    list.push(item.name);
                }
            });

            this[name] = list;
        },
        getCandidateCategory(code) {
            let candidates = this.$store.state.candidates.bod;
            let category = -1;

            candidates.forEach(function(item) {
                if(item.key == code) {
                    category = item.type;
                }
            });

            return category;
        },
        setCandidates(data, type) {
            let candidates = this.$store.state.candidates[type];
            let list = [];

            candidates.forEach(function(item) {
                if(data.indexOf(item.key) != -1) {
                    list.push(item.name);
                }
            });

            this[type] = list;
        },
        exit() {
            this.$router.push('/election/ratings');
            this.clear();
        },
        clear() {
            let state = this.$store.state;

            state.selected.bod.splice(0, state.selected.bod.length);
            state.selected.elecom_of.splice(0, state.selected.elecom_of.length);
            state.selected.elecom_ep.splice(0, state.selected.elecom_ep.length);
            state.selected.elecom_ci.splice(0, state.selected.elecom_ci.length);
            state.selected.audit.splice(0, state.selected.audit.length);
            state.election.ballot = null;
        },
        getLoader() {
            return process.env.VUE_APP_MEDIA_URL + "images/loader/gear-spinner.gif";
        },
    }
}
</script>

<style scoped>
.papsi-ballot-footer {
    bottom: 0;
    left: 0;
}
</style>