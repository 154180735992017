<template>
    <div class="flex">
        <CountdownItemControl v-bind:value="days" v-bind:title="lang('day')" />
        <CountdownItemControl v-bind:value="hour" v-bind:title="lang('hour')" />
        <CountdownItemControl v-bind:value="min" v-bind:title="lang('min')" />
        <CountdownItemControl v-bind:value="sec" v-bind:title="lang('sec')" last />
    </div>
</template>

<script>
import CountdownItemControl from './CountdownItemControl.vue';
import mixin from './../../mixin.js';

export default {
    name: 'v-countdown',
    mixins: [
        mixin,
    ],
    components: {
        CountdownItemControl,
    },
    data() {
        return {
            days: '00',
            hour: '00',
            min: '00',
            sec: '00',
        }
    },
    mounted() {
        let that = this,
            state = that.$store.state;

        function tick() 
        {
            let loop = setTimeout(function() {
                let now = new Date().getTime(),
                    date,
                    reg_start = new Date(state.election.reg_start),
                    vot_start = new Date(state.election.voting_start),
                    vot_end = new Date(state.election.voting_end),
                    message;
                
                // Registration is still not open.
                if(reg_start.getTime() > now)
                {
                    message = that.lang('registration.starts.in');
                    date = new Date(state.election.reg_start);
                }

                // Voting is still not open.
                else if(reg_start.getTime() < now && vot_start.getTime() > now)
                {
                    that.$store.state.election.reg_started = true;

                    message = that.lang('voting.starts.in');
                    date = new Date(state.election.voting_start);
                }

                // Voting is still not done.
                else if(vot_start.getTime() < now && vot_end.getTime() > now)
                {
                    that.$store.state.election.voting_started = true;

                    message = that.lang('voting.ends.in');
                    date = new Date(state.election.voting_end);
                }
                else 
                {
                    that.$store.state.election.voting_ended = true;
                }
                
                let distance = (date - now),
                    days = Math.floor(distance / (1000 * 60 * 60 * 24)),
                    hour = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)),
                    min = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60)),
                    sec = Math.floor((distance % (1000 * 60)) / 1000);

                    that.days = (days < 10 ? '0' + days : days).toString();
                    that.hour = (hour < 10 ? '0' + hour : hour).toString();
                    that.min = (min < 10 ? '0' + min : min).toString();
                    that.sec = (sec < 10 ? '0' + sec : sec).toString();
                    
                    that.$emit('ticking', {
                        message: message,
                    });
                    
                    if(now > vot_end) 
                    {
                        that.$emit('ended');
                        clearTimeout(loop);
                    }
                    else 
                    {
                        clearTimeout(loop);
                        tick();
                    }

            }, 1000);
        }

        tick();
    }
}
</script>
