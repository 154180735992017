<template>
    <div>
        <PromptControl @closed="promptClosed"
            @ok="ok"
            @cancel="cancel"
            v-bind:show="show"
            v-bind:title="lang('discard.changes')"
            v-bind:message="lang('do.you.want.to.discard.all.changes')" />
        <ContentWrapperControl class="mt-4">
            <div class="bg-white p-4 rounded shadow">
                <AlertControl @closes="closed" 
                    v-bind:show="display" 
                    v-bind:theme="theme"
                    autoclose>
                    {{ message }}
                </AlertControl>
                <div class="mb-4">
                    <TextControl @typing="typing" 
                        v-bind:value="old_password"
                        v-bind:placeholder="lang('old.password')"
                        name="old_password"
                        type="password"
                        required />
                </div>
                <div class="mb-4">
                    <div>
                        <TextControl @typing="typing" 
                            @strong="strong"
                            v-bind:value="new_password"
                            v-bind:placeholder="lang('new.password')"
                            name="new_password" 
                            type="password" 
                            required
                            strength />
                    </div>
                    <div v-text="lang('use.eight.or.more.characters.with.a.mix.of.letters.numbers.and.special.characters')" class="mt-1 text-xs text-grey-dark italic"></div>
                </div>
                <div class="mb-4">
                    <TextControl @typing="typing" 
                        v-bind:value="con_password"
                        v-bind:placeholder="lang('confirm.password')"
                        name="con_password" 
                        type="password" 
                        required 
                        nohint />
                </div>
                <div>
                    <ButtonControl v-on:click="update" v-bind:disabled="disabled">
                        {{ lang('update') }}
                    </ButtonControl>
                </div>
            </div>
        </ContentWrapperControl>
    </div>
</template>

<script>
import { onBeforeRouteLeave } from 'vue-router';
import AlertControl from './../../components/controls/AlertControl.vue';
import ButtonControl from './../../components/controls/ButtonControl.vue';
import ContentWrapperControl from './../../components/controls/ContentWrapperControl.vue';
import PromptControl from './../../components/controls/PromptControl.vue';
import TextControl from './../../components/controls/TextControl.vue';
import User from './../../services/User.js';
import mixin from './../../mixin.js';

export default {
    mixins: [
        mixin,
    ],
    components: {
        AlertControl,
        ButtonControl,
        ContentWrapperControl,
        PromptControl,
        TextControl,
    },
    data() {
        return {
            theme: 'error',
            disabled: true,
            display: false,
            message: '',
            old_password: '',
            new_password: '',
            con_password: '',
            changed: false,
            show: false,
            href: null,
            passed: false,
        }
    },
    mounted() {
        this.$emit('checkauth');
        this.$emit('nofooter', false);

        let title = this.lang('edit.password');

        this.$emit('shownavbar', {
            title: title,
            show: true,
            back: true,
        });

        let that = this;

        onBeforeRouteLeave((to) => {
            that.href = to.fullPath;

            if(that.changed) {
                that.show = true;

                return false;
            }
        });
    },
    methods: {
        strong(passed) {
            this.passed = passed;
        },
        ok() {
            this.changed = false;
            this.$router.push(this.href);
        },
        cancel() {
            this.show = false;
        },
        promptClosed() {
            this.show = false;
        },
        typing(obj) {
            this[obj.name] = obj.value;
            this.enableButton();
        },
        update() {
            let that = this,
                state = this.$store.state,
                uuid = state.uuid;

            this.$emit('showloader');
            User.updatePassword(uuid, this.old_password, this.new_password, this.con_password).then(function() {
                let password = '';

                for(let i = 1; i <= that.new_password.length; i++) {
                    password += '*';
                }

                that.theme = 'success';
                that.message = that.lang('password.was.successfully.updated');
                that.display = true;
                that.disabled = true;
                that.changed = false;
                that.old_password = '';
                that.new_password = '';
                that.con_password = '';
                state.password = password;
                that.$emit('hideloader');
            }).catch(function(error) {
                let status = error.response.status,
                    message = error.response.data.message;

                if(status != 403) {
                    that.theme = 'error';
                    that.message = message;
                    that.display = true;
                    that.changed = false;
                    that.disabled = true;
                    that.old_password = '';
                    that.new_password = '';
                    that.con_password = '';

                    that.$emit('hideloader');
                }
                else {
                    that.$emit('terminate', status);
                }
            });
        },
        enableButton() {
            this.disabled = (this.old_password.length == 0 || this.new_password.length == 0 || this.con_password.length == 0 || !this.passed);
            this.changed = (this.old_password.length != 0 || this.new_password.length != 0 || this.con_password.length != 0);
        },
        closed() {
            this.display = false;
        },
    }
}
</script>