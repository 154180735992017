<template>
    <ContentWrapper class="mt-4">
        <div class="bg-white shadow rounded overflow-hidden">
            <div>
                <img v-bind:src="image" class="block w-full" />
            </div>
            <div class="px-4 text-sm leading-normal mt-4">
                <div class="pb-4">
                    PAFCPIC stands for the Philippine Army Finance Center Producers Integrated Cooperative, affectionately
                    referred to by its members as &quot;PAPSI&quot;. PAFCPIC is a multi-awarded cooperative offering financial
                    services through deposits and loans to its members serving in the Armed Forces of the Philippines.
                </div>
                <div class="pb-4">
                    Its membership consists of military and civilian personnel (both active and retired) of the Philippine
                    Army, Philippine Navy and Philippine Air Force, as well as their immediate dependents. With its current
                    membership totaling more than 160,000 members. Its main office is located along Bayani Road in Fort
                    Bonifacio, Taguig City and has 25 satellite offices and 27 liaison offices across the country.
                </div>
                <div class="pb-4">
                    PAFCPIC is the first institution-based category winner of Gawad para sa Pinakatanging Kooperatiba
                    (Gawad PITAK) of the Land Bank of the Philippines in 2004. It was the first cooperative recognized for
                    Family Welfare and Community Development in 2004 and for Labor-Management Relations in 2013
                    under the Search for Model Companies by the Department of Trade and Industry (DTI) Center for
                    Industrial Competitiveness. It was also the first cooperative to have been bestowed the Philippine
                    Quality Challenge Level 2 Award in 2015 by the DTI Competitiveness Bureau in partnership with the
                    Philippine Society for Quality Inc.
                </div>
                <div class="pb-4">
                    PAFCPIC is currently registered with the Cooperative Development Authority (CDA) under Registry
                    Number 9520-16000421 dated 16 October 2009.
                </div>
            </div>
        </div>
    </ContentWrapper>
</template>

<script>
import ContentWrapper from './../../components/controls/ContentWrapperControl.vue';
import mixin from './../../mixin.js';

export default {
    mixins: [
        mixin,
    ],
    components: {
        ContentWrapper,
    },
    data() {
        return {
            image: '',
        }
    },
    mounted() {
        let title = this.lang('our.company');

        this.$emit('shownavbar', {
            title: title,
            show: true,
            back: true,
        });

        this.image = this.getMediaPath('/misc/papsi-ho.jpg');
    },
}
</script>