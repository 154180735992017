<template>
    <div>
        <MessageBoxControl @closed="messageClosed"
            v-bind:show="message.show"
            v-bind:title="message.title"
            v-bind:message="message.message"
            v-bind:theme="message.theme" />
        <PromptControl @ok="ok"
            @closed="closePrompt"
            v-bind:show="ask.show"
            v-bind:title="ask.title"
            v-bind:message="ask.message" />
        <ContentWrapperControl class="mt-4">
            <div class="bg-white rounded border-b border-grey shadow overflow-hidden mb-4">
                <div>
                    <img v-bind:src="image" class="w-full block mb-6" />
                </div>
            </div>
            <div v-show="!$store.state.express && enabled" class="bg-white border-b border-grey shadow rounded overflow-hidden">
                <div v-show="!countdown">
                    <img v-bind:src="getLoader()" class="block mx-auto" />
                </div>
                <div v-show="!ended && countdown">
                    <div class="flex px-3 py-3 border-b bg-grey-lightest">
                        <div v-show="!ended" class="text-md pr-3 flex-grow flex items-center text-grey-darkest">
                            <span v-text="sub"></span>
                        </div>
                        <div class="flex">
                            <div v-on:click="showMevStatus" class="flex mr-3">
                                <div>
                                    <div v-bind:class="{
                                        'bg-green' : mev,
                                        'bg-red' : !mev,
                                    }" class="bg-grey rounded-full papsi-status-dim"></div>
                                </div>
                                <div class="pl-1 text-xs font-bold text-grey-darkest">MEV</div>
                            </div>
                            <div v-if="mev" class="flex">
                                <div>
                                    <div v-bind:class="{
                                        'bg-green' : signed,
                                        'bg-red' : !signed,
                                    }" class="bg-grey rounded-full papsi-status-dim"></div>
                                </div>
                                <div class="pl-1 text-xs font-bold text-grey-darkest">SIGNED</div>
                            </div>
                        </div>
                    </div>
                    <div class="p-3 pb-2">
                        <CountdownControl @ticking="ticking" @ended="electionEnds" />
                    </div>
                </div>
                <div v-show="started && ended && countdown" class="p-3 text-center text-grey-dark text-sm">
                    {{ lang('election.has.been.officially.closed') }}
                </div>
                <div v-show="show && countdown" class="px-3 pb-3">
                    <ButtonControl v-bind:theme="theme" v-on:click="register">
                        {{ label }}
                    </ButtonControl>
                </div>
            </div>
        </ContentWrapperControl>
    </div>
</template>

<script>
import ButtonControl from './../components/controls/ButtonControl.vue';
import ContentWrapperControl from './../components/controls/ContentWrapperControl.vue';
import CountdownControl from './../components/controls/CountdownControl.vue';
import Election from './../services/Election.js';
import MessageBoxControl from './../components/controls/MessageBoxControl.vue';
import OTP from './../services/OTP.js';
import PromptControl from './../components/controls/PromptControl.vue';
import mixin from './../mixin.js';

export default {
    mixins: [
        mixin,
    ],
    components: {
        ButtonControl,
        ContentWrapperControl,
        CountdownControl,
        MessageBoxControl,
        PromptControl,
    },
    data() {
        return {
            username: '',
            image: '',
            show: false,
            label: null,
            theme: 'success',
            href: '/election/policy',
            sub: null,
            message: {
                title: null,
                theme: 'error',
                message: '',
                show: false,
            },
            ask: {
                show: false,
                title: 'Registration',
                message: '',
            },
            prompt: '',
            started: false,
            ended: true,
            voted: false,
            registered: false,
            can_vote: false,
            success: false,
            countdown: false,
            enabled: false,
            mev: false,
            signed: false,
        }
    },
    mounted() {
        this.$emit('checkauth');
        this.$emit('nofooter', false);
        this.$emit('shownavbar', {
            type: 'primary',
        });

        this.image = this.getMediaPath('/ad/general_assembly.jpg');
        this.username = this.$store.state.username;
        this.started = this.$store.state.election.voting_started;
        this.ended = this.$store.state.election.voting_ended;
        this.voted = this.$store.state.election.voted;
        this.registered = this.$store.state.election.registered;
        this.can_vote = this.$store.state.election.can_vote;
        this.enabled = process.env.VUE_APP_ELECTION_ENABLED;
        this.mev = this.$store.state.election.mev;
        this.signed = this.$store.state.election.signed;
    },
    methods: {
        showMevStatus() {
            if(this.mev) {
                this.message.theme = 'success';
                this.message.title = this.lang('mev');
                this.message.message = 'You are a member entitled to vote.';
            }
            else {
                this.message.theme = 'error';
                this.message.title = 'Non-MEV';
                this.message.message = 'You are not entitled to vote.';
            }

            this.message.show = true;
        },
        ticking(obj) {
            let election = this.$store.state.election;

            this.countdown = true;
            this.sub = obj.message;

            if(!election.registered && !election.voted && election.reg_started && !election.reg_ended)
            {
                this.label = this.lang('register');
                this.show = true;
            }
            else if(election.registered && !election.voted && election.voting_started && !election.voting_ended)
            {
                this.label = this.lang('vote');
                this.show = true;
            }
            else if (election.registered && election.voted)
            {
                this.show = false;
            }
            else 
            {
                this.show = false;
            }
        },
        register() {
            if(this.$store.state.election.registered) {
                this.ok();
            }
            else {
                this.ask.message = 'Are you sure you want to register to Annual General Assembly Meeting and Election?';
                this.ask.show = true;
            }
        },
        ok() {
            let that = this;

            if(that.$store.state.election.registered && !that.$store.state.election.voted)
            {
                that.$router.push(that.href);
            }
            else
            {
                this.$emit('showloader');
                Election.register(this.$store.state.uuid).then(function(response) {
                    that.prompt = response.data.data.message;
                    that.$store.state.election.registered = true;
                    that.registered = true;
                    that.success = true;

                    if(response.data.data.status == 0) {
                        that.sendOtp();
                    }
                    else {
                        that.message.title = that.lang('congratulations');
                        that.message.theme = 'success';
                        that.message.message = that.prompt;
                        that.message.show = true;
                        that.$emit('hideloader');
                    }
                })
                .catch(function(error) {
                    let status = error.response.status,
                        message = error.response.data.message;

                    if(status != 403) {
                        that.message.title = that.lang('error');
                        that.message.theme = 'error';
                        that.message.message = message;
                        that.message.show = true;
                        that.$emit('hideloader');
                    }
                    else {
                        that.$emit('terminate', status);
                    }
                });
            }
        },
        sendOtp() {
            let store = this.$store.state,
                that = this,
                type = 5;

            OTP.request(type, store.first_name, store.email, store.mobile).then(function() {
                if(that.$store.state.election.voting_started)
                {
                    that.$store.state.election.started = true;
                }

                that.message.title = that.lang('congratulations');
                that.message.theme = 'success';
                that.message.message = that.prompt;
                that.message.show = true;
                that.$emit('hideloader');
            })
            .catch(function(error) {
                let status = error.response.status,
                    message = error.response.data.message;

                if(status != 403) {
                    that.message.title = that.lang('error');
                    that.message.theme = 'error';
                    that.message.message = message;
                    that.message.show = true;
                    that.$emit('hideloader');
                }
                else {
                    that.$emit('terminate', status);
                }
            });
        },
        electionEnds() {
            this.ended = true;
            this.started = true;
            this.show = false;
        },
        messageClosed() {
            this.message.show = false;

            if(this.$store.state.election.started && !this.voted)
            {
                this.$router.push(this.href);
            }
        },
        getLoader() {
            return process.env.VUE_APP_MEDIA_URL + "images/loader/gear-spinner.gif";
        },
        closePrompt() {
            this.ask.show = false;
        },
    },
}
</script>

<style scoped>
.papsi-status-dim {
    width: 10px;
    height: 10px;
}
</style>