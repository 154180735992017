<template>
    <div>
        <MessageBoxControl @closed="close"
            v-bind:theme="theme"
            v-bind:show="display"
            v-bind:title="title"
            v-bind:message="message" />
        <PromptControl @ok="ok"
            @closed="closed"
            v-bind:show="show" 
            title="Vote"
            message="Proceed to submit your votes?" />
        <div class="w-full h-screen flex flex-col">
            <div class="bg-primary">
                <div class="pt-3 px-3 flex">
                    <div class="h-menu w-menu">
                        <img v-on:click="back" v-show="false" src="@/assets/icons/papsi_back.svg" />
                    </div>
                    <div class="flex-grow text-center pt-6 pb-3">
                        <div class="text-xl text-white font-bold uppercase pt-6">
                            Vote Summary
                        </div>
                        <div class="text-white opacity-75 text-sm mt-2 italic">Please check the names below before submitting your votes.</div>
                    </div>
                    <div class="h-menu w-menu"></div>
                </div>
                <div class="max-w-limit w-full mx-auto">
                    <div class="px-4">
                        <div class="bg-white py-2 w-full rounded-t"></div>
                    </div>
                </div>
            </div>
            <div class="max-w-limit w-full mx-auto">
                <div class="px-4">
                    <div class="bg-white py-2 w-full rounded-b border-b"></div>
                </div>
            </div>
            <div class="flex-grow w-full overflow-auto py-4">
                <ContentWrapper>
                    <div>
                        <VoteCardControl v-for="item in categories" 
                            v-bind:id="item.id"
                            v-bind:key="item.id"
                            v-bind:title="item.title"
                            v-bind:href="item.href"
                            v-bind:type="item.type"
                            v-bind:color="item.theme" />
                    </div>
                    <div ref="footerspacer"></div>
                </ContentWrapper>
            </div>
        </div>
        <div ref="footerbar" class="p-3 bg-white border-t fixed w-full z-footer papsi-summary-footer">
            <ButtonControl v-on:click="vote" theme="error">Submit</ButtonControl>
        </div>
    </div>
</template>

<script>
import ButtonControl from './../../components/controls/ButtonControl.vue';
import ContentWrapper from './../../components/controls/ContentWrapperControl.vue';
import Election from './../../services/Election.js';
import MessageBoxControl from './../../components/controls/MessageBoxControl.vue';
import PromptControl from './../../components/controls/PromptControl.vue';
import VoteCardControl from './../../components/controls/VoteCardControl.vue';

export default {
    components: {
        ButtonControl,
        ContentWrapper,
        MessageBoxControl,
        PromptControl,
        VoteCardControl,
    },
    data() {
        return {
            show: false,
            display: false,
            title: '',
            message: '',
            theme: 'error',
            categories: [
                {
                    id: 'bod',
                    title: 'Board of Directors',
                    type: 'Officer',
                    href: '/election/vote/bod',
                    theme: 'bg-theme-bod',
                },
                {
                    id: 'bod',
                    title: 'Board of Directors',
                    type: 'Enlisted Personnel',
                    href: '/election/vote/bod',
                    theme: 'bg-theme-bod',
                },
                {
                    id: 'bod',
                    title: 'Board of Directors',
                    type: 'Civilian',
                    href: '/election/vote/bod',
                    theme: 'bg-theme-bod',
                },
                {
                    id: 'elecom_of',
                    title: 'Election Committee',
                    type: 'Officer',
                    href: '/election/vote/elecom/officer',
                    theme: 'bg-theme-elecom',
                },
                {
                    id: 'elecom_ep',
                    title: 'Election Committee',
                    type: 'Enlisted Personnel',
                    href: '/election/vote/elecom/enlisted-personnel',
                    theme: 'bg-theme-elecom',
                },
                {
                    id: 'elecom_ci',
                    title: 'Election Committee',
                    type: 'Civilian',
                    href: '/election/vote/elecom/civilian',
                    theme: 'bg-theme-elecom',
                },
                {
                    id: 'audit',
                    title: 'Audit & Supervisory Committee',
                    type: 'No Category',
                    href: '/election/vote/audit',
                    theme: 'bg-theme-adcom',
                },
            ],
        }
    },
    mounted() {
        this.$emit('checkauth');
        this.$emit('nofooter', true);
        this.$store.state.election.done = true;

        let state = this.$store.state.election;

        if(!state.registered || state.voted) {
            this.$router.push('/dashboard');
        }

        this.setSpacer();
    },
    methods: {
        setSpacer() {
            this.$refs.footerspacer.style.height = (parseInt(this.$refs.footerbar.offsetHeight) + 20) + "px";
        },
        back() {
            this.$router.back();
        },
        closed() {
            this.show = false;
        },
        close() {
            this.display = false;
        },
        vote() {
            this.show = true;
        },
        ok() {
            this.$emit('showloader');

            let state = this.$store.state.selected,
                that = this,
                data = [];

            state.bod.forEach(function(item) {
                data.push(item);
            });

            state.elecom_of.forEach(function(item) {
                data.push(item);
            });

            state.elecom_ep.forEach(function(item) {
                data.push(item);
            });

            state.elecom_ci.forEach(function(item) {
                data.push(item);
            });

            state.audit.forEach(function(item) {
                data.push(item);
            });

            Election.vote(this.$store.state.uuid, JSON.stringify(data)).then(function(response) {
                that.$store.state.election.ballot = response.data.data.ballot;
                that.$store.state.election.voted = true;
                that.theme = 'success';
                that.title = 'Voted!';
                that.message = 'You have successfully casted your votes.';
                that.display = true;
                that.$router.push('/election/ballot');
            })
            .catch(function(error) {
                let status = error.response.status,
                    message = error.response.data.message;

                if(status != 403) {
                    that.theme = 'error';
                    that.title = 'Error';
                    that.message = message;
                    that.display = true;
                    that.$emit('hideloader');
                }
                else {
                    that.$emit('terminate', status);
                }
            });
        }
    }
}
</script>

<style scoped>
.papsi-summary-footer {
    bottom: 0;
    left: 0;
}
</style>